// -----------------------------------------------------------------Imports---
import { Theme } from '@mui/material';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import SxPropsModel from '../../models/SxPropsModel';

// -----------------------------------------------------------------Exports---
export const listItemButtonSxProps = (open: boolean): SxPropsModel => {
    return {
        main: {
            justifyContent: open ? 'initial' : 'center',
            minHeight: 48,
            px: 2.5,
        },
        sub: {
            justifyContent: 'initial',
            pl: 5,
        },
    }
}

export const listItemIconSxProps = (open: boolean): SxPropsModel => {
    return {
        main: {
            justifyContent: 'center',
            minWidth: 0,
            mr: open ? 3 : 'auto',
        },
        sub: {
            justifyContent: 'center',
            minWidth: 0,
            mr: 1,
        },
    }
}

export const listItemTextSxProps = (open: boolean): SxPropsModel => {
    return {
        main: {
            opacity: open ? 1 : 0,
        },
    }
}

export const listItemStyles: CSSPropertiesModel = {
    main: {
        display: 'block',
    },
}

export const ListSxProps = (theme: Theme): SxPropsModel => {
    return {
        main: {
            marginTop: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(8),
            },
        },
    }
}
