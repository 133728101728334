// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import CountryEntity from '../../entities/CountryEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import CountryAddOrUpdateRequestModel from '../../models/country/CountryAddOrUpdateRequestModel';
import CountryListRequestModel from '../../models/country/CountryListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getCountries(data?: CountryListRequestModel): Promise<ResponseModel<CountryEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.country.getCountries,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async addCountry(data: CountryAddOrUpdateRequestModel): Promise<ResponseModel<CountryEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.country.addCountry,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async updateCountry(id: number, data?: CountryAddOrUpdateRequestModel): Promise<ResponseModel<CountryEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.country.updateCountry.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async removeCountry(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.country.removeCountry.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        );
    }
}

const CountryService = new LocalService();

// -----------------------------------------------------------------Exports---
export default CountryService;
