// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    ChangeEvent,
    CSSProperties,
    Dispatch,
    Fragment,
    MouseEvent,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    DebouncedState,
    useDebounce,
} from 'use-debounce';

import {
    Add,
    Delete,
    ExpandMore,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Settings,
} from '@mui/icons-material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Button,
    Collapse,
    Fab,
    Grid,
    IconButton,
    Paper,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import {
    fabStyles,
    PaperSxProps,
    TableRowSxProps,
} from './PartnersPage.style';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    debounceDelay,
} from '../../Global';

import DialogComponent from '../../components/dialog/DialogComponent';

import CountryEntity from '../../entities/CountryEntity';
import DeliveryAddressEntity from '../../entities/DeliveryAddressEntity';
import PartnerEntity from '../../entities/PartnerEntity';
import UserEntity from '../../entities/UserEntity';

import DialogTypeEnumerator from '../../enumerators/DialogTypeEnumerator';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import PaginationRequestModel from '../../models/PaginationRequestModel';
import ResponseModel from '../../models/ResponseModel';
import TableColumnModel from '../../models/TableColumnModel';
import DeliveryAddressAddOrUpdateRequestModel, { initialDeliveryAddressAddOrUpdateRequestModel } from '../../models/deliveryAddress/DeliveryAddressAddOrUpdateRequestModel';
import PartnerAddOrUpdateRequestModel, { initialPartnerAddOrUpdateRequestModel } from '../../models/partner/PartnerAddOrUpdateRequestModel';
import PartnerFilterRequestModel, { initialPartnerFilterRequestModel } from '../../models/partner/PartnerFilterRequestModel';
import PartnerListRequestModel from '../../models/partner/PartnerListRequestModel';
import UserListRequestModel from '../../models/user/UserListRequestModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import CountryService from '../../services/country/CountryService';
import DeliveryAddressService from '../../services/deliveryAddress/DeliveryAddressService';
import PartnerService from '../../services/partner/PartnerService';
import UserService from '../../services/user/UserService';

// ----------------------------------------------------------------Privates---
const columns: TableColumnModel[] = [
    { name: 'Azonosító', align: 'right', hidden: true, },
    { name: 'Név', align: 'left', hidden: false, },
    { name: 'Irányítószám', align: 'left', hidden: false, },
    { name: 'Város', align: 'left', hidden: false, },
    { name: 'Utcanév', align: 'left', hidden: false, },
    { name: 'Utcajelleg', align: 'left', hidden: false, },
    { name: 'Házszám', align: 'left', hidden: false, },
    { name: 'Adószám', align: 'left', hidden: false, },
    { name: 'Bankszámlaszám', align: 'left', hidden: false, },
    { name: 'Telefonszám', align: 'left', hidden: false, },
    { name: 'E-mail', align: 'left', hidden: false, },
    { name: 'Kapcsolat név', align: 'left', hidden: false, },
    { name: 'Kapcsolat telefonszám', align: 'left', hidden: false, },
    { name: 'Kapcsolat e-mail', align: 'left', hidden: false, },
    { name: 'Megjegyzés', align: 'left', hidden: false, },
    { name: 'Típus', align: 'left', hidden: false, },
    { name: 'Ország azonosító', align: 'right', hidden: true, },
    { name: 'Ország megnevezés', align: 'left', hidden: false, },
    { name: 'Létrehozva', align: 'center', hidden: false, },
    { name: 'Módosítva', align: 'center', hidden: false, },
    { name: 'Létrehozó azonosító', align: 'right', hidden: true, },
    { name: 'Létrehozó felhasználónév', align: 'left', hidden: false, },
    { name: 'Módosító azonosító', align: 'right', hidden: true, },
    { name: 'Módosító felhasználónév', align: 'left', hidden: false, },
];

const subColumns: TableColumnModel[] = [
    { name: 'Azonosító', align: 'left', hidden: true, },
    { name: 'Név', align: 'left', hidden: false, },
    { name: 'Irányítószám', align: 'left', hidden: false, },
    { name: 'Város', align: 'left', hidden: false, },
    { name: 'Utcanév', align: 'left', hidden: false, },
    { name: 'Utcajelleg', align: 'left', hidden: false, },
    { name: 'Házszám', align: 'left', hidden: false, },
    { name: 'Telefonszám', align: 'left', hidden: false, },
    { name: 'E-mail', align: 'left', hidden: false, },
    { name: 'Kapcsolat név', align: 'left', hidden: false, },
    { name: 'Kapcsolat telefonszám', align: 'left', hidden: false, },
    { name: 'Kapcsolat e-mail', align: 'left', hidden: false, },
    { name: 'Megjegyzés', align: 'left', hidden: false, },
    { name: 'Ország azonosító', align: 'right', hidden: true, },
    { name: 'Ország megnevezés', align: 'left', hidden: false, },
    { name: 'Létrehozva', align: 'center', hidden: false, },
    { name: 'Módosítva', align: 'center', hidden: false, },
    { name: 'Létrehozó azonosító', align: 'right', hidden: true, },
    { name: 'Létrehozó felhasználónév', align: 'left', hidden: false, },
    { name: 'Módosító azonosító', align: 'right', hidden: true, },
    { name: 'Módosító felhasználónév', align: 'left', hidden: false, },
];

interface SubRowState {
    open: boolean;
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

const subRowDataInitialState: SubRowState = {
    open: false,
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

interface SubRowProps {
    addButtonStyle: CSSProperties;
    data: PartnerEntity;
    handleClickAddDeliveryAddress: (partnerId: number) => void;
    handleClickUpdateDeliveryAddress: (id: number, name: string, postCode: string, city: string, streetName: string, streetType: string, houseNumber: string, telephone: string, email: string, contactName: string, contactTelephone: string, contactEmail: string, comment: string, countryId: number) => void;
    handleClickUpdatePartner: (id: number, name: string, postCode: string, city: string, streetName: string, streetType: string, houseNumber: string, taxNumber: string, bankAccountNumber: string, telephone: string, email: string, contactName: string, contactTelephone: string, contactEmail: string, comment: string, type: string, countryId: number) => void;
    handleClickRemoveDeliveryAddress: (id: number, name: string) => void;
    handleClickRemovePartner: (id: number, name: string) => void;
    tableRowSx: SxProps;
}

interface State {
    partnerDialogState: PartnerDialogState;
    deliveryAddressDialogState: DeliveryAddressDialogState;
    partnerSetterState: PartnerAddOrUpdateRequestModel;
    deliveryAddressSetterState: DeliveryAddressAddOrUpdateRequestModel;
    partnerFilterState: PartnerFilterRequestModel;
    partners: PartnerEntity[];
    refresh: boolean;
    users: UserEntity[];
    countries: CountryEntity[];
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

interface PartnerDialogState {
    isOpened: boolean;
    dialogType: DialogTypeEnumerator;
    id: number;
}

interface DeliveryAddressDialogState {
    isOpened: boolean;
    type: DialogTypeEnumerator;
    id: number;
}

const initialState: State = {
    partnerDialogState: {
        isOpened: false,
        dialogType: DialogTypeEnumerator.Add,
        id: -1,
    },
    deliveryAddressDialogState: {
        isOpened: false,
        type: DialogTypeEnumerator.Add,
        id: -1,
    },
    partnerSetterState: initialPartnerAddOrUpdateRequestModel,
    deliveryAddressSetterState: initialDeliveryAddressAddOrUpdateRequestModel,
    partnerFilterState: initialPartnerFilterRequestModel,
    partners: [],
    refresh: false,
    users: [],
    countries: [],
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

const SubRowData = ({ addButtonStyle, data, handleClickAddDeliveryAddress, handleClickUpdateDeliveryAddress, handleClickUpdatePartner, handleClickRemoveDeliveryAddress, handleClickRemovePartner, tableRowSx }: SubRowProps): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const [state, setState]: [SubRowState, Dispatch<SetStateAction<SubRowState>>] = useState<SubRowState>(subRowDataInitialState);

    useEffect((): void => {
        setState((prevState: SubRowState): SubRowState => ({
            ...prevState,
            rowCount: data.deliveryAddresses?.length ?? 0,
        }));
    }, [data.deliveryAddresses?.length]);

    const typeToString = useCallback((type: string): string => {
        switch (type) {
            case 'S': {
                return 'Szállító';
            }
            case 'B': {
                return 'Vevő';
            }
            case 'A': {
                return 'Szállító/Vevő';
            }
            default: {
                return 'Ismeretlen';
            }
        }
    }, []);

    // --------------------------------------------------------------Events---
    const handleClickTableRow = useCallback((): void => {
        setState((prevState: SubRowState): SubRowState => ({
            ...prevState,
            open: !prevState.open,
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState: SubRowState): SubRowState => ({
            ...prevState,
            page: newPage,
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: SubRowState): SubRowState => ({
            ...prevState,
            rowsPerPage: Number(event.target.value),
            page: 0,
        }));
    }, []);

    // --------------------------------------------------------------Return---
    return (
        <Fragment>
            <TableRow sx={tableRowSx}>
                <TableCell align="center">
                    <IconButton onClick={handleClickTableRow} size="small">
                        {state.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {((!columns[0].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[0].align}>{data.id!.toLocaleString()}</TableCell>)}
                {((!columns[1].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[1].align}>{data.name!}</TableCell>)}
                {((!columns[2].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[2].align}>{data.postCode!}</TableCell>)}
                {((!columns[3].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[3].align}>{data.city!}</TableCell>)}
                {((!columns[4].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[4].align}>{data.streetName!}</TableCell>)}
                {((!columns[5].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[5].align}>{data.streetType!}</TableCell>)}
                {((!columns[6].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[6].align}>{data.houseNumber!}</TableCell>)}
                {((!columns[7].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[7].align}>{data.taxNumber!}</TableCell>)}
                {((!columns[8].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[8].align}>{data.bankAccountNumber!}</TableCell>)}
                {((!columns[9].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[9].align}>{data.telephone!}</TableCell>)}
                {((!columns[10].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[10].align}>{data.email!}</TableCell>)}
                {((!columns[11].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[11].align}>{data.contactName!}</TableCell>)}
                {((!columns[12].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[12].align}>{data.contactTelephone!}</TableCell>)}
                {((!columns[13].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[13].align}>{data.contactEmail!}</TableCell>)}
                {((!columns[14].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[14].align}>{data.comment!}</TableCell>)}
                {((!columns[15].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[15].align}>{typeToString(data.type!)}</TableCell>)}
                {((!columns[16].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[16].align}>{data.countryId!.toLocaleString()}</TableCell>)}
                {((!columns[17].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[17].align}>{data.country?.name + ' (' + data.country?.isoCode + ')'}</TableCell>)}
                {((!columns[18].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[18].align}>{moment(data.createdAt!).format(dateTimeFormat)}</TableCell>)}
                {((!columns[19].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[19].align}>{moment(data.updatedAt!).format(dateTimeFormat)}</TableCell>)}
                {((!columns[20].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[20].align}>{data.createdBy!}</TableCell>)}
                {((!columns[21].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[21].align}>{data.createdByUser!.username!}</TableCell>)}
                {((!columns[22].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[22].align}>{data.updatedBy!}</TableCell>)}
                {((!columns[23].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[23].align}>{data.updatedByUser!.username!}</TableCell>)}
                <TableCell align="center"><Fab color="warning" onClick={(): void => handleClickUpdatePartner(data.id!, data.name!, data.postCode!, data.city!, data.streetName!, data.streetType!, data.houseNumber!, data.taxNumber!, data.bankAccountNumber!, data.telephone!, data.email!, data.contactName!, data.contactTelephone!, data.contactEmail!, data.comment!, data.type!, data.countryId!)} size="small"><Settings /></Fab></TableCell>
                <TableCell align="center"><Fab color="error" onClick={(): void => handleClickRemovePartner(data.id!, data.name!)} size="small"><Delete /></Fab></TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={columns.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={state.open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography component="div" variant="h6">
                                Telephelyek
                            </Typography>

                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {subColumns
                                            .filter((subColumn: TableColumnModel): boolean => !subColumn.hidden)
                                            .map((subColumn: TableColumnModel, index: number): JSX.Element => (
                                                <TableCell align={subColumn.align} key={index}>
                                                    <TableSortLabel>
                                                        {subColumn.name}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell align="center" />
                                        <TableCell align="center" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.deliveryAddresses?.map((deliveryAddress: DeliveryAddressEntity, index: number): JSX.Element => {
                                        return (
                                            <TableRow key={index} sx={tableRowSx}>
                                                {((!subColumns[0].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[0].align}>{deliveryAddress.id!.toLocaleString()}</TableCell>)}
                                                {((!subColumns[1].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[1].align}>{deliveryAddress.name!}</TableCell>)}
                                                {((!subColumns[2].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[2].align}>{deliveryAddress.postCode!}</TableCell>)}
                                                {((!subColumns[3].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[3].align}>{deliveryAddress.city!}</TableCell>)}
                                                {((!subColumns[4].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[4].align}>{deliveryAddress.streetName!}</TableCell>)}
                                                {((!subColumns[5].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[5].align}>{deliveryAddress.streetType!}</TableCell>)}
                                                {((!subColumns[6].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[6].align}>{deliveryAddress.houseNumber!}</TableCell>)}
                                                {((!subColumns[7].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[7].align}>{deliveryAddress.telephone!}</TableCell>)}
                                                {((!subColumns[8].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[8].align}>{deliveryAddress.email!}</TableCell>)}
                                                {((!subColumns[9].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[9].align}>{deliveryAddress.contactName!}</TableCell>)}
                                                {((!subColumns[10].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[10].align}>{deliveryAddress.contactTelephone!}</TableCell>)}
                                                {((!subColumns[11].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[11].align}>{deliveryAddress.contactEmail!}</TableCell>)}
                                                {((!subColumns[12].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[12].align}>{deliveryAddress.comment!}</TableCell>)}
                                                {((!subColumns[13].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[13].align}>{deliveryAddress.countryId!.toLocaleString()}</TableCell>)}
                                                {((!subColumns[14].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[14].align}>{deliveryAddress.country?.name + ' (' + deliveryAddress.country?.isoCode + ')'}</TableCell>)}
                                                {((!subColumns[15].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[15].align}>{moment(deliveryAddress.createdAt!).format(dateTimeFormat)}</TableCell>)}
                                                {((!subColumns[16].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[16].align}>{moment(deliveryAddress.updatedAt!).format(dateTimeFormat)}</TableCell>)}
                                                {((!subColumns[17].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[17].align}>{deliveryAddress.createdBy!}</TableCell>)}
                                                {((!subColumns[18].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[18].align}>{deliveryAddress.createdByUser!.username!}</TableCell>)}
                                                {((!subColumns[19].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[19].align}>{deliveryAddress.updatedBy!}</TableCell>)}
                                                {((!subColumns[20].hidden) || (AuthenticationService.adminState())) && (<TableCell align={subColumns[20].align}>{deliveryAddress.updatedByUser!.username!}</TableCell>)}
                                                <TableCell align="center"><Fab color="warning" onClick={(): void => handleClickUpdateDeliveryAddress(deliveryAddress.id!, deliveryAddress.name!, deliveryAddress.postCode!, deliveryAddress.city!, deliveryAddress.streetName!, deliveryAddress.streetType!, deliveryAddress.houseNumber!, deliveryAddress.telephone!, deliveryAddress.email!, deliveryAddress.contactName!, deliveryAddress.contactTelephone!, deliveryAddress.contactEmail!, deliveryAddress.comment!, deliveryAddress.countryId!)} size="small"><Settings /></Fab></TableCell>
                                                <TableCell align="center"><Fab color="error" onClick={(): void => handleClickRemoveDeliveryAddress(deliveryAddress.id!, deliveryAddress.name!)} size="small"><Delete /></Fab></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                            <TablePagination component="div" count={state.rowCount} onPageChange={handlePageChange} onRowsPerPageChange={handleRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} style={{ display: 'flex', }} />

                            <Button aria-label="add" color="success" onClick={(): void => handleClickAddDeliveryAddress(data.id!)} style={addButtonStyle} variant="contained">
                                <Add />
                            </Button>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

const PartnersPage = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);
    const [partnerDebouncedState]: [PartnerFilterRequestModel, DebouncedState<(value: PartnerFilterRequestModel) => void>] = useDebounce<PartnerFilterRequestModel>(state.partnerFilterState, debounceDelay);

    useEffect((): void => {
        const getPartners = async (): Promise<void> => {
            const filterData: PartnerFilterRequestModel = {
                id: partnerDebouncedState.id! < 1 ? undefined : partnerDebouncedState.id,
                createdAtStart: partnerDebouncedState.createdAtStart == null ? undefined : partnerDebouncedState.createdAtStart,
                createdAtEnd: partnerDebouncedState.createdAtEnd == null ? undefined : partnerDebouncedState.createdAtEnd,
                updatedAtStart: partnerDebouncedState.updatedAtStart == null ? undefined : partnerDebouncedState.updatedAtStart,
                updatedAtEnd: partnerDebouncedState.updatedAtEnd == null ? undefined : partnerDebouncedState.updatedAtEnd,
                createdBy: partnerDebouncedState.createdBy,
                updatedBy: partnerDebouncedState.updatedBy,
                name: partnerDebouncedState.name?.length === 0 ? undefined : partnerDebouncedState.name,
                postCode: partnerDebouncedState.postCode?.length === 0 ? undefined : partnerDebouncedState.postCode,
                city: partnerDebouncedState.city?.length === 0 ? undefined : partnerDebouncedState.city,
                streetName: partnerDebouncedState.streetName?.length === 0 ? undefined : partnerDebouncedState.streetName,
                streetType: partnerDebouncedState.streetType?.length === 0 ? undefined : partnerDebouncedState.streetType,
                houseNumber: partnerDebouncedState.houseNumber?.length === 0 ? undefined : partnerDebouncedState.houseNumber,
                taxNumber: partnerDebouncedState.taxNumber?.length === 0 ? undefined : partnerDebouncedState.taxNumber,
                bankAccountNumber: partnerDebouncedState.bankAccountNumber?.length === 0 ? undefined : partnerDebouncedState.bankAccountNumber,
                telephone: partnerDebouncedState.telephone?.length === 0 ? undefined : partnerDebouncedState.telephone,
                email: partnerDebouncedState.email?.length === 0 ? undefined : partnerDebouncedState.email,
                contactName: partnerDebouncedState.contactName?.length === 0 ? undefined : partnerDebouncedState.contactName,
                contactTelephone: partnerDebouncedState.contactTelephone?.length === 0 ? undefined : partnerDebouncedState.contactTelephone,
                contactEmail: partnerDebouncedState.contactEmail?.length === 0 ? undefined : partnerDebouncedState.contactEmail,
                comment: partnerDebouncedState.comment?.length === 0 ? undefined : partnerDebouncedState.comment,
                countryId: partnerDebouncedState.countryId,
                type: partnerDebouncedState.type,
            }

            const paginationData: PaginationRequestModel = {
                currentPage: state.page + 1,
                dataPerPage: state.rowsPerPage,
            }

            const requestData: PartnerListRequestModel = {
                filter: filterData,
                orderBy: 'name_asc',
                pagination: paginationData,
            }

            const response: ResponseModel<PartnerEntity[]> = await PartnerService.getPartners(requestData);

            if (response.status === 200) {
                const responsePartner: PartnerEntity[] = response.data as PartnerEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    partners: responsePartner,
                    rowCount: responsePartner.length,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getPartners();
    }, [
        partnerDebouncedState.bankAccountNumber,
        partnerDebouncedState.city,
        partnerDebouncedState.comment,
        partnerDebouncedState.contactEmail,
        partnerDebouncedState.contactName,
        partnerDebouncedState.contactTelephone,
        partnerDebouncedState.countryId,
        partnerDebouncedState.createdAtEnd,
        partnerDebouncedState.createdAtStart,
        partnerDebouncedState.createdBy,
        partnerDebouncedState.email,
        partnerDebouncedState.houseNumber,
        partnerDebouncedState.id,
        partnerDebouncedState.name,
        partnerDebouncedState.postCode,
        partnerDebouncedState.streetName,
        partnerDebouncedState.streetType,
        partnerDebouncedState.taxNumber,
        partnerDebouncedState.telephone,
        partnerDebouncedState.type,
        partnerDebouncedState.updatedAtEnd,
        partnerDebouncedState.updatedAtStart,
        partnerDebouncedState.updatedBy,
        state.page,
        state.refresh,
        state.rowsPerPage,
    ]);

    useEffect((): void => {
        const getCountries = async (): Promise<void> => {
            const response: ResponseModel<CountryEntity[]> = await CountryService.getCountries();

            if (response.status === 200) {
                const responseCountry: CountryEntity[] = response.data as CountryEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    countries: responseCountry,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        const getUsers = async (): Promise<void> => {
            const requestData: UserListRequestModel = {
                orderBy: 'username_asc',
            }

            const response: ResponseModel<UserEntity[]> = await UserService.getUsers(requestData);

            if (response.status === 200) {
                const responseUser: UserEntity[] = response.data as UserEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    users: responseUser,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getCountries();
        getUsers();
    }, []);

    const getCountryByIdFromState = useCallback((id?: number): CountryEntity | null => {
        return state.countries.find((value: CountryEntity): boolean => value.id === id) ?? null;
    }, [state.countries]);

    const getUserByIdFromState = useCallback((id?: number | null): UserEntity | null => {
        return state.users.find((value: UserEntity): boolean => value.id === id) ?? null;
    }, [state.users]);

    const clearDialogState = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerDialogState: initialState.partnerDialogState,
            deliveryAddressDialogState: initialState.deliveryAddressDialogState,
            partnerSetterState: initialState.partnerSetterState,
            deliveryAddressSetterState: initialState.deliveryAddressSetterState,
        }));
    }, []);

    const validatePartnerModifiedData = useCallback((): boolean => {
        if (
            (!state.partnerSetterState.name)
            || ((state.partnerSetterState.name)
                && (state.partnerSetterState.name.length === 0))
        ) {
            toast.error('"Név" mező kitöltése kötelező.');
            return false;
        }

        if (!state.partnerSetterState.countryId) {
            toast.error('"Ország" érték kiválasztása kötelező.');
            return false;
        }

        return true;
    }, [
        state.partnerSetterState.countryId,
        state.partnerSetterState.name,
    ]);

    const addPartnerData = useCallback(async (): Promise<boolean> => {
        if (!validatePartnerModifiedData()) {
            return false;
        }

        const requestData: PartnerAddOrUpdateRequestModel = {
            name: state.partnerSetterState.name,
            postCode: state.partnerSetterState.postCode,
            city: state.partnerSetterState.city,
            streetName: state.partnerSetterState.streetName,
            streetType: state.partnerSetterState.streetType,
            houseNumber: state.partnerSetterState.houseNumber,
            taxNumber: state.partnerSetterState.taxNumber,
            bankAccountNumber: state.partnerSetterState.bankAccountNumber,
            telephone: state.partnerSetterState.telephone,
            email: state.partnerSetterState.email,
            contactName: state.partnerSetterState.contactName,
            contactTelephone: state.partnerSetterState.contactTelephone,
            contactEmail: state.partnerSetterState.contactEmail,
            comment: state.partnerSetterState.comment,
            countryId: state.partnerSetterState.countryId,
            type: state.partnerSetterState.type,
        }

        const response: ResponseModel<PartnerEntity> = await PartnerService.addPartner(requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.partnerSetterState.bankAccountNumber,
        state.partnerSetterState.city,
        state.partnerSetterState.comment,
        state.partnerSetterState.contactEmail,
        state.partnerSetterState.contactName,
        state.partnerSetterState.contactTelephone,
        state.partnerSetterState.countryId,
        state.partnerSetterState.email,
        state.partnerSetterState.houseNumber,
        state.partnerSetterState.name,
        state.partnerSetterState.postCode,
        state.partnerSetterState.streetName,
        state.partnerSetterState.streetType,
        state.partnerSetterState.taxNumber,
        state.partnerSetterState.telephone,
        state.partnerSetterState.type,
        validatePartnerModifiedData,
    ]);

    const updatePartnerData = useCallback(async (): Promise<boolean> => {
        if (!validatePartnerModifiedData()) {
            return false;
        }

        const requestData: PartnerAddOrUpdateRequestModel = {
            name: state.partnerSetterState.name,
            postCode: state.partnerSetterState.postCode,
            city: state.partnerSetterState.city,
            streetName: state.partnerSetterState.streetName,
            streetType: state.partnerSetterState.streetType,
            houseNumber: state.partnerSetterState.houseNumber,
            taxNumber: state.partnerSetterState.taxNumber,
            bankAccountNumber: state.partnerSetterState.bankAccountNumber,
            telephone: state.partnerSetterState.telephone,
            email: state.partnerSetterState.email,
            contactName: state.partnerSetterState.contactName,
            contactTelephone: state.partnerSetterState.contactTelephone,
            contactEmail: state.partnerSetterState.contactEmail,
            comment: state.partnerSetterState.comment,
            countryId: state.partnerSetterState.countryId,
            type: state.partnerSetterState.type,
        }

        const response: ResponseModel<PartnerEntity> = await PartnerService.updatePartner(state.partnerDialogState.id, requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.partnerDialogState.id,
        state.partnerSetterState.bankAccountNumber,
        state.partnerSetterState.city,
        state.partnerSetterState.comment,
        state.partnerSetterState.contactEmail,
        state.partnerSetterState.contactName,
        state.partnerSetterState.contactTelephone,
        state.partnerSetterState.countryId,
        state.partnerSetterState.email,
        state.partnerSetterState.houseNumber,
        state.partnerSetterState.name,
        state.partnerSetterState.postCode,
        state.partnerSetterState.streetName,
        state.partnerSetterState.streetType,
        state.partnerSetterState.taxNumber,
        state.partnerSetterState.telephone,
        state.partnerSetterState.type,
        validatePartnerModifiedData,
    ]);

    const removePartnerData = useCallback(async (): Promise<void> => {
        const response: ResponseModel<string> = await PartnerService.removePartner(state.partnerDialogState.id);

        if (response.status === 200) {
            setState((prevState: State): State => ({
                ...prevState,
                refresh: !prevState.refresh,
            }));
        } else {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }
        }
    }, [state.partnerDialogState.id]);

    const validateDeliveryAddressModifiedData = useCallback((): boolean => {
        if (
            (!state.deliveryAddressSetterState.name)
            || ((state.deliveryAddressSetterState.name)
                && (state.deliveryAddressSetterState.name.length === 0))
        ) {
            toast.error('"Név" mező kitöltése kötelező.');
            return false;
        }

        if (!state.deliveryAddressSetterState.countryId) {
            toast.error('"Ország" érték kiválasztása kötelező.');
            return false;
        }

        return true;
    }, [
        state.deliveryAddressSetterState.countryId,
        state.deliveryAddressSetterState.name,
    ]);

    const addDeliveryAddressData = useCallback(async (): Promise<boolean> => {
        if (!validateDeliveryAddressModifiedData()) {
            return false;
        }

        const requestData: DeliveryAddressAddOrUpdateRequestModel = {
            partnerId: state.deliveryAddressSetterState.partnerId,
            name: state.deliveryAddressSetterState.name,
            postCode: state.deliveryAddressSetterState.postCode,
            city: state.deliveryAddressSetterState.city,
            streetName: state.deliveryAddressSetterState.streetName,
            streetType: state.deliveryAddressSetterState.streetType,
            houseNumber: state.deliveryAddressSetterState.houseNumber,
            telephone: state.deliveryAddressSetterState.telephone,
            email: state.deliveryAddressSetterState.email,
            contactName: state.deliveryAddressSetterState.contactName,
            contactTelephone: state.deliveryAddressSetterState.contactTelephone,
            contactEmail: state.deliveryAddressSetterState.contactEmail,
            comment: state.deliveryAddressSetterState.comment,
            countryId: state.deliveryAddressSetterState.countryId,
        }

        const response: ResponseModel<DeliveryAddressEntity> = await DeliveryAddressService.addDeliveryAddress(requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.deliveryAddressSetterState.city,
        state.deliveryAddressSetterState.comment,
        state.deliveryAddressSetterState.contactEmail,
        state.deliveryAddressSetterState.contactName,
        state.deliveryAddressSetterState.contactTelephone,
        state.deliveryAddressSetterState.countryId,
        state.deliveryAddressSetterState.email,
        state.deliveryAddressSetterState.houseNumber,
        state.deliveryAddressSetterState.name,
        state.deliveryAddressSetterState.partnerId,
        state.deliveryAddressSetterState.postCode,
        state.deliveryAddressSetterState.streetName,
        state.deliveryAddressSetterState.streetType,
        state.deliveryAddressSetterState.telephone,
        validateDeliveryAddressModifiedData,
    ]);

    const updateDeliveryAddressData = useCallback(async (): Promise<boolean> => {
        if (!validateDeliveryAddressModifiedData()) {
            return false;
        }

        const requestData: DeliveryAddressAddOrUpdateRequestModel = {
            partnerId: state.deliveryAddressSetterState.partnerId,
            name: state.deliveryAddressSetterState.name,
            postCode: state.deliveryAddressSetterState.postCode,
            city: state.deliveryAddressSetterState.city,
            streetName: state.deliveryAddressSetterState.streetName,
            streetType: state.deliveryAddressSetterState.streetType,
            houseNumber: state.deliveryAddressSetterState.houseNumber,
            telephone: state.deliveryAddressSetterState.telephone,
            email: state.deliveryAddressSetterState.email,
            contactName: state.deliveryAddressSetterState.contactName,
            contactTelephone: state.deliveryAddressSetterState.contactTelephone,
            contactEmail: state.deliveryAddressSetterState.contactEmail,
            comment: state.deliveryAddressSetterState.comment,
            countryId: state.deliveryAddressSetterState.countryId,
        }

        const response: ResponseModel<DeliveryAddressEntity> = await DeliveryAddressService.updateDeliveryAddress(state.deliveryAddressDialogState.id, requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.deliveryAddressDialogState.id,
        state.deliveryAddressSetterState.city,
        state.deliveryAddressSetterState.comment,
        state.deliveryAddressSetterState.contactEmail,
        state.deliveryAddressSetterState.contactName,
        state.deliveryAddressSetterState.contactTelephone,
        state.deliveryAddressSetterState.countryId,
        state.deliveryAddressSetterState.email,
        state.deliveryAddressSetterState.houseNumber,
        state.deliveryAddressSetterState.name,
        state.deliveryAddressSetterState.partnerId,
        state.deliveryAddressSetterState.postCode,
        state.deliveryAddressSetterState.streetName,
        state.deliveryAddressSetterState.streetType,
        state.deliveryAddressSetterState.telephone,
        validateDeliveryAddressModifiedData,
    ]);

    const removeDeliveryAddressData = useCallback(async (): Promise<void> => {
        const response: ResponseModel<string> = await DeliveryAddressService.removeDeliveryAddress(state.deliveryAddressDialogState.id);

        if (response.status === 200) {
            setState((prevState: State): State => ({
                ...prevState,
                refresh: !prevState.refresh,
            }));
        } else {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }
        }
    }, [state.deliveryAddressDialogState.id]);

    // --------------------------------------------------------------Events---
    const handleClickAddPartnerButton = useCallback((): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            partnerDialogState: {
                ...prevState.partnerDialogState,
                isOpened: true,
                dialogType: DialogTypeEnumerator.Add,
            },
        }));
    }, [clearDialogState]);

    const handleClickUpdatePartnerButton = useCallback((id: number, name: string, postCode: string, city: string, streetName: string, streetType: string, houseNumber: string, taxNumber: string, bankAccountNumber: string, telephone: string, email: string, contactName: string, contactTelephone: string, contactEmail: string, comment: string, type: string, countryId: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerDialogState: {
                ...prevState.partnerDialogState,
                isOpened: true,
                dialogType: DialogTypeEnumerator.Update,
                id: id,
            },
            partnerSetterState: {
                name: name,
                postCode: postCode,
                city: city,
                streetName: streetName,
                streetType: streetType,
                houseNumber: houseNumber,
                taxNumber: taxNumber,
                bankAccountNumber: bankAccountNumber,
                telephone: telephone,
                email: email,
                contactName: contactName,
                contactTelephone: contactTelephone,
                contactEmail: contactEmail,
                comment: comment,
                type: type,
                countryId: getCountryByIdFromState(countryId)?.id,
            },
        }));
    }, [getCountryByIdFromState]);

    const handleClickRemovePartnerButton = useCallback((id: number, name: string): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            partnerDialogState: {
                ...prevState.partnerDialogState,
                isOpened: true,
                dialogType: DialogTypeEnumerator.Remove,
                id: id,
            },
            partnerSetterState: {
                name: name,
            },
        }));
    }, [clearDialogState]);

    const handleClickAddDeliveryAddressButton = useCallback((partnerId: number): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressDialogState: {
                ...prevState.deliveryAddressDialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Add,
            },
            deliveryAddressSetterState: {
                partnerId: partnerId,
            },
        }));
    }, [clearDialogState]);

    const handleClickUpdateDeliveryAddressButton = useCallback((id: number, name: string, postCode: string, city: string, streetName: string, streetType: string, houseNumber: string, telephone: string, email: string, contactName: string, contactTelephone: string, contactEmail: string, comment: string, countryId: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressDialogState: {
                ...prevState.deliveryAddressDialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Update,
                id: id,
            },
            deliveryAddressSetterState: {
                name: name,
                postCode: postCode,
                city: city,
                streetName: streetName,
                streetType: streetType,
                houseNumber: houseNumber,
                telephone: telephone,
                email: email,
                contactName: contactName,
                contactTelephone: contactTelephone,
                contactEmail: contactEmail,
                comment: comment,
                countryId: getCountryByIdFromState(countryId)?.id,
            },
        }));
    }, [getCountryByIdFromState]);

    const handleClickRemoveDeliveryAddressButton = useCallback((id: number, name: string): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressDialogState: {
                ...prevState.deliveryAddressDialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Remove,
                id: id,
            },
            deliveryAddressSetterState: {
                name: name,
            },
        }));
    }, [clearDialogState]);

    const handleClickClosePartnerDialog = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerDialogState: {
                ...prevState.partnerDialogState,
                isOpened: false,
            },
        }));
    }, []);

    const handleClickSaveButtonFromPartnerDialog = useCallback(async (): Promise<void> => {
        let isSaved: boolean = true;

        switch (state.partnerDialogState.dialogType) {
            case DialogTypeEnumerator.Add: {
                isSaved = await addPartnerData();
                break;
            }
            case DialogTypeEnumerator.Update: {
                isSaved = await updatePartnerData();
                break;
            }
            case DialogTypeEnumerator.Remove: {
                await removePartnerData();
                break;
            }
            default: {
                break;
            }
        }

        if (!isSaved) {
            return;
        }

        clearDialogState();
        handleClickClosePartnerDialog();

        toast.success('Sikeres ' + state.partnerDialogState.dialogType.toLocaleLowerCase() + '.');
    }, [
        addPartnerData,
        clearDialogState,
        state.partnerDialogState.dialogType,
        handleClickClosePartnerDialog,
        removePartnerData,
        updatePartnerData,
    ]);

    const handleClickCloseDeliveryAddressDialog = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressDialogState: {
                ...prevState.deliveryAddressDialogState,
                isOpened: false,
            },
        }));
    }, []);

    const handleClickSaveButtonFromDeliveryAddressDialog = useCallback(async (): Promise<void> => {
        let isSaved: boolean = true;

        switch (state.deliveryAddressDialogState.type) {
            case DialogTypeEnumerator.Add: {
                isSaved = await addDeliveryAddressData();
                break;
            }
            case DialogTypeEnumerator.Update: {
                isSaved = await updateDeliveryAddressData();
                break;
            }
            case DialogTypeEnumerator.Remove: {
                await removeDeliveryAddressData();
                break;
            }
            default: {
                break;
            }
        }

        if (!isSaved) {
            return;
        }

        clearDialogState();
        handleClickCloseDeliveryAddressDialog();

        toast.success('Sikeres ' + state.deliveryAddressDialogState.type.toLocaleLowerCase() + '.');
    }, [
        addDeliveryAddressData,
        clearDialogState,
        state.deliveryAddressDialogState.type,
        handleClickCloseDeliveryAddressDialog,
        removeDeliveryAddressData,
        updateDeliveryAddressData,
    ]);

    const handleChangePartnerNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerPostCodeDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                postCode: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerCityDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                city: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerStreetNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                streetName: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerStreetTypeDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                streetType: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerHouseNumberDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                houseNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerTaxNumberDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                taxNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerBankAccountNumberDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                bankAccountNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerTelephoneDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                telephone: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerEmailDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                email: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerContactNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                contactName: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerContactTelephoneDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                contactTelephone: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerContactEmailDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                contactEmail: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerCommentDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                comment: event.target.value,
            },
        }));
    }, []);

    const handleChangePartnerTypeDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                type: event.target.value,
                ...prevState.partnerSetterState,
            },
        }));
    }, []);

    const handleChangePartnerCountryDialog = useCallback((event: SyntheticEvent, newValue: CountryEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerSetterState: {
                ...prevState.partnerSetterState,
                countryId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressPostCodeDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                postCode: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressCityDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                city: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressStreetNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                streetName: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressStreetTypeDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                streetType: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressHouseNumberDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                houseNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressTelephoneDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                telephone: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressEmailDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                email: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressContactNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                contactName: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressContactTelephoneDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                contactTelephone: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressContactEmailDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                contactEmail: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressCommentDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                comment: event.target.value,
            },
        }));
    }, []);

    const handleChangeDeliveryAddressCountryDialog = useCallback((event: SyntheticEvent, newValue: CountryEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressSetterState: {
                ...prevState.deliveryAddressSetterState,
                countryId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeCreatedAtStartPartnerFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                createdAtStart: event,
            },
        }));
    }, []);

    const handleChangeCreatedAtEndPartnerFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                createdAtEnd: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtStartPartnerFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                updatedAtStart: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtEndPartnerFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                updatedAtEnd: event,
            },
        }));
    }, []);

    const handleChangeCreatedByPartnerFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                createdBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeUpdatedByPartnerFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                updatedBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeIdPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                id: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeNamePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleChangePostCodePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                postCode: event.target.value,
            },
        }));
    }, []);

    const handleChangeCityPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                city: event.target.value,
            },
        }));
    }, []);

    const handleChangeStreetNamePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                streetName: event.target.value,
            },
        }));
    }, []);

    const handleChangeStreetTypePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                streetType: event.target.value,
            },
        }));
    }, []);

    const handleChangeHouseNumberPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                houseNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangeTaxNumberPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                taxNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangeBankAccountNumberPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                bankAccountNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangeTelephonePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                telephone: event.target.value,
            },
        }));
    }, []);

    const handleChangeEmailPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                email: event.target.value,
            },
        }));
    }, []);

    const handleChangeContactNamePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                contactName: event.target.value,
            },
        }));
    }, []);

    const handleChangeContactTelephonePartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                contactTelephone: event.target.value,
            },
        }));
    }, []);

    const handleChangeContactEmailPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                contactEmail: event.target.value,
            },
        }));
    }, []);

    const handleChangeCommentPartnerFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            partnerFilterState: {
                ...prevState.partnerFilterState,
                comment: event.target.value,
            },
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            page: newPage,
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            rowsPerPage: Number(event.target.value),
            page: 0,
        }));
    }, []);

    // --------------------------------------------------------------Return---
    return (
        <>
            <Paper sx={PaperSxProps(theme).filter}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>
                            Szűrés
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LocalizationProvider adapterLocale="hu" dateAdapter={AdapterMoment}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Partner létrehozva kezdet" onChange={handleChangeCreatedAtStartPartnerFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.partnerFilterState.createdAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Partner létrehozva vég" onChange={handleChangeCreatedAtEndPartnerFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.partnerFilterState.createdAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Partner módosítva kezdet" onChange={handleChangeUpdatedAtStartPartnerFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.partnerFilterState.updatedAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Partner módosítva vég" onChange={handleChangeUpdatedAtEndPartnerFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.partnerFilterState.updatedAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeCreatedByPartnerFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Partner létrehozó felhasználó" />}
                                        value={getUserByIdFromState(state.partnerFilterState.createdBy)}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeUpdatedByPartnerFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Partner módosító felhasználó" />}
                                        value={getUserByIdFromState(state.partnerFilterState.updatedBy)}
                                    />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label="Partner azonosító" onChange={handleChangeIdPartnerFilter} type="number" value={state.partnerFilterState.id} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner név" onChange={handleChangeNamePartnerFilter} type="text" value={state.partnerFilterState.name} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner irányítószám" onChange={handleChangePostCodePartnerFilter} type="text" value={state.partnerFilterState.postCode} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner város" onChange={handleChangeCityPartnerFilter} type="text" value={state.partnerFilterState.city} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner utcanév" onChange={handleChangeStreetNamePartnerFilter} type="text" value={state.partnerFilterState.streetName} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner utcajelleg" onChange={handleChangeStreetTypePartnerFilter} type="text" value={state.partnerFilterState.streetType} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner házszám" onChange={handleChangeHouseNumberPartnerFilter} type="text" value={state.partnerFilterState.houseNumber} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner adószám" onChange={handleChangeTaxNumberPartnerFilter} type="text" value={state.partnerFilterState.taxNumber} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner bankszámlaszám" onChange={handleChangeBankAccountNumberPartnerFilter} type="text" value={state.partnerFilterState.bankAccountNumber} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner telefonszám" onChange={handleChangeTelephonePartnerFilter} type="text" value={state.partnerFilterState.telephone} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner e-mail" onChange={handleChangeEmailPartnerFilter} type="text" value={state.partnerFilterState.email} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner kapcsolat név" onChange={handleChangeContactNamePartnerFilter} type="text" value={state.partnerFilterState.contactName} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner kapcsolat telefonszám" onChange={handleChangeContactTelephonePartnerFilter} type="text" value={state.partnerFilterState.contactTelephone} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner kapcsolat e-mail" onChange={handleChangeContactEmailPartnerFilter} type="text" value={state.partnerFilterState.contactEmail} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Partner megjegyzés" multiline onChange={handleChangeCommentPartnerFilter} type="text" value={state.partnerFilterState.comment} />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </AccordionDetails>
                </Accordion>
            </Paper>

            <Paper>
                <TableContainer>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" />
                                {columns
                                    .filter((column: TableColumnModel): boolean => (!column.hidden) || (AuthenticationService.adminState()))
                                    .map((column: TableColumnModel, index: number): JSX.Element => (
                                        <TableCell align={column.align} key={index}>
                                            <TableSortLabel>
                                                {column.name}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                }
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.partners.map((partner: PartnerEntity, index: number): JSX.Element => {
                                return (
                                    <SubRowData
                                        addButtonStyle={fabStyles.addDeliveryAddressData}
                                        data={partner}
                                        key={index}
                                        handleClickAddDeliveryAddress={handleClickAddDeliveryAddressButton}
                                        handleClickUpdateDeliveryAddress={handleClickUpdateDeliveryAddressButton}
                                        handleClickUpdatePartner={handleClickUpdatePartnerButton}
                                        handleClickRemoveDeliveryAddress={handleClickRemoveDeliveryAddressButton}
                                        handleClickRemovePartner={handleClickRemovePartnerButton}
                                        tableRowSx={TableRowSxProps(theme).bodyRow}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination component="div" count={state.rowCount} onPageChange={handlePageChange} onRowsPerPageChange={handleRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />
            </Paper>

            <Fab aria-label="add" color="success" onClick={handleClickAddPartnerButton} style={fabStyles.addPartner}><Add /></Fab>

            <DialogComponent
                dataToRemoved={state.partnerSetterState.name ?? ''}
                dialogType={state.partnerDialogState.dialogType}
                fields={
                    <>
                        <TextField autoFocus fullWidth inputProps={{ maxLength: 250, }} label="Név" onChange={handleChangePartnerNameDialog} type="text" value={state.partnerSetterState.name} />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label="Irányítószám" onChange={handleChangePartnerPostCodeDialog} type="text" value={state.partnerSetterState.postCode} />
                        <TextField fullWidth inputProps={{ maxLength: 50, }} label="Város" onChange={handleChangePartnerCityDialog} type="text" value={state.partnerSetterState.city} />
                        <TextField fullWidth inputProps={{ maxLength: 50, }} label="Utcanév" onChange={handleChangePartnerStreetNameDialog} type="text" value={state.partnerSetterState.streetName} />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label="Utcajelleg" onChange={handleChangePartnerStreetTypeDialog} type="text" value={state.partnerSetterState.streetType} />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label="Házszám" onChange={handleChangePartnerHouseNumberDialog} type="text" value={state.partnerSetterState.houseNumber} />
                        <TextField fullWidth inputProps={{ maxLength: 25, }} label="Adószám" onChange={handleChangePartnerTaxNumberDialog} type="text" value={state.partnerSetterState.taxNumber} />
                        <TextField fullWidth inputProps={{ maxLength: 50, }} label="Bankszámlaszám" onChange={handleChangePartnerBankAccountNumberDialog} type="text" value={state.partnerSetterState.bankAccountNumber} />
                        <TextField fullWidth inputProps={{ maxLength: 25, }} label="Telefonszám" onChange={handleChangePartnerTelephoneDialog} type="text" value={state.partnerSetterState.telephone} />
                        <TextField fullWidth inputProps={{ maxLength: 100, }} label="E-mail" onChange={handleChangePartnerEmailDialog} type="text" value={state.partnerSetterState.email} />
                        <TextField fullWidth inputProps={{ maxLength: 250, }} label="Kapcsolat név" onChange={handleChangePartnerContactNameDialog} type="text" value={state.partnerSetterState.contactName} />
                        <TextField fullWidth inputProps={{ maxLength: 25, }} label="Kapcsolat telefonszám" onChange={handleChangePartnerContactTelephoneDialog} type="text" value={state.partnerSetterState.contactTelephone} />
                        <TextField fullWidth inputProps={{ maxLength: 100, }} label="Kapcsolat e-mail" onChange={handleChangePartnerContactEmailDialog} type="text" value={state.partnerSetterState.contactEmail} />
                        <TextField fullWidth label="Megjegyzés" multiline onChange={handleChangePartnerCommentDialog} type="text" value={state.partnerSetterState.comment} />
                        <TextField fullWidth label="Típus" onChange={handleChangePartnerTypeDialog} type="text" value={state.partnerSetterState.type} />
                        <Autocomplete
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            getOptionLabel={(option: CountryEntity) => `${option.name} [${option.isoCode}] (${option.id?.toString()})` ?? ''}
                            onChange={handleChangePartnerCountryDialog}
                            options={state.countries}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Ország" />}
                            value={getCountryByIdFromState(state.partnerSetterState.countryId)}
                        />
                    </>
                }
                isOpened={state.partnerDialogState.isOpened}
                handleClickClose={handleClickClosePartnerDialog}
                handleClickSave={handleClickSaveButtonFromPartnerDialog}
            />

            <DialogComponent
                dataToRemoved={state.deliveryAddressSetterState.name ?? ''}
                dialogType={state.deliveryAddressDialogState.type}
                fields={
                    <>
                        <TextField autoFocus fullWidth inputProps={{ maxLength: 250, }} label="Név" onChange={handleChangeDeliveryAddressNameDialog} type="text" value={state.deliveryAddressSetterState.name} />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label="Irányítószám" onChange={handleChangeDeliveryAddressPostCodeDialog} type="text" value={state.deliveryAddressSetterState.postCode} />
                        <TextField fullWidth inputProps={{ maxLength: 50, }} label="Város" onChange={handleChangeDeliveryAddressCityDialog} type="text" value={state.deliveryAddressSetterState.city} />
                        <TextField fullWidth inputProps={{ maxLength: 50, }} label="Utcanév" onChange={handleChangeDeliveryAddressStreetNameDialog} type="text" value={state.deliveryAddressSetterState.streetName} />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label="Utcajelleg" onChange={handleChangeDeliveryAddressStreetTypeDialog} type="text" value={state.deliveryAddressSetterState.streetType} />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label="Házszám" onChange={handleChangeDeliveryAddressHouseNumberDialog} type="text" value={state.deliveryAddressSetterState.houseNumber} />
                        <TextField fullWidth inputProps={{ maxLength: 25, }} label="Telefonszám" onChange={handleChangeDeliveryAddressTelephoneDialog} type="text" value={state.deliveryAddressSetterState.telephone} />
                        <TextField fullWidth inputProps={{ maxLength: 100, }} label="E-mail" onChange={handleChangeDeliveryAddressEmailDialog} type="text" value={state.deliveryAddressSetterState.email} />
                        <TextField fullWidth inputProps={{ maxLength: 250, }} label="Kapcsolat név" onChange={handleChangeDeliveryAddressContactNameDialog} type="text" value={state.deliveryAddressSetterState.contactName} />
                        <TextField fullWidth inputProps={{ maxLength: 25, }} label="Kapcsolat telefonszám" onChange={handleChangeDeliveryAddressContactTelephoneDialog} type="text" value={state.deliveryAddressSetterState.contactTelephone} />
                        <TextField fullWidth inputProps={{ maxLength: 100, }} label="Kapcsolat e-mail" onChange={handleChangeDeliveryAddressContactEmailDialog} type="text" value={state.deliveryAddressSetterState.contactEmail} />
                        <TextField fullWidth label="Megjegyzés" multiline onChange={handleChangeDeliveryAddressCommentDialog} type="text" value={state.deliveryAddressSetterState.comment} />
                        <Autocomplete
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            getOptionLabel={(option: CountryEntity) => `${option.name} [${option.isoCode}] (${option.id?.toString()})` ?? ''}
                            onChange={handleChangeDeliveryAddressCountryDialog}
                            options={state.countries}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Ország" />}
                            value={getCountryByIdFromState(state.deliveryAddressSetterState.countryId)}
                        />
                    </>
                }
                isOpened={state.deliveryAddressDialogState.isOpened}
                handleClickClose={handleClickCloseDeliveryAddressDialog}
                handleClickSave={handleClickSaveButtonFromDeliveryAddressDialog}
            />
        </>
    );
}

// -----------------------------------------------------------------Exports---
export default PartnersPage;
