// -----------------------------------------------------------------Imports---
import { ReportHandler } from 'web-vitals';

// ----------------------------------------------------------------Privates---
const reportWebVitals = (onPerfEntry?: ReportHandler | undefined): void => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals')
            .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }): void => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);
            });
    }
}

// -----------------------------------------------------------------Exports---
export default reportWebVitals;
