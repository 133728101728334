// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import GroupEntity from '../../entities/GroupEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import GroupAddOrUpdateRequestModel from '../../models/group/GroupAddOrUpdateRequestModel';
import GroupListRequestModel from '../../models/group/GroupListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getGroups(data?: GroupListRequestModel): Promise<ResponseModel<GroupEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.group.getGroups,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async addGroup(data: GroupAddOrUpdateRequestModel): Promise<ResponseModel<GroupEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.group.addGroup,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async updateGroup(id: number, data?: GroupAddOrUpdateRequestModel): Promise<ResponseModel<GroupEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.group.updateGroup.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async removeGroup(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.group.removeGroup.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        );
    }
}

const GroupService = new LocalService();

// -----------------------------------------------------------------Exports---
export default GroupService;
