// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    ChangeEvent,
    Dispatch,
    MouseEvent,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    DebouncedState,
    useDebounce,
} from 'use-debounce';

import {
    Add,
    Delete,
    ExpandMore,
    Settings,
} from '@mui/icons-material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    AutocompleteRenderInputParams,
    Chip,
    Fab,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import {
    fabStyles,
    PaperSxProps,
    TableRowSxProps,
} from './UnitOfMeasuresPage.style';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    debounceDelay,
} from '../../Global';

import DialogComponent from '../../components/dialog/DialogComponent';

import UnitOfMeasureEntity from '../../entities/UnitOfMeasureEntity';
import UserEntity from '../../entities/UserEntity';

import DialogTypeEnumerator from '../../enumerators/DialogTypeEnumerator';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import NamedBooleanModel from '../../models/NamedBooleanModel';
import PaginationRequestModel from '../../models/PaginationRequestModel';
import ResponseModel from '../../models/ResponseModel';
import TableColumnModel from '../../models/TableColumnModel';
import UnitOfMeasureAddOrUpdateRequestModel, { initialUnitOfMeasureAddOrUpdateRequestModel } from '../../models/unitOfMeasure/UnitOfMeasureAddOrUpdateRequestModel';
import UnitOfMeasureFilterRequestModel, { initialUnitOfMeasureFilterRequestModel } from '../../models/unitOfMeasure/UnitOfMeasureFilterRequestModel';
import UnitOfMeasureListRequestModel from '../../models/unitOfMeasure/UnitOfMeasureListRequestModel';
import UserListRequestModel from '../../models/user/UserListRequestModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import UnitOfMeasureService from '../../services/unitOfMeasure/UnitOfMeasureService';
import UserService from '../../services/user/UserService';

// ----------------------------------------------------------------Privates---
const columns: TableColumnModel[] = [
    { name: 'Azonosító', align: 'right', hidden: true, },
    { name: 'Rövid megnevezés', align: 'left', hidden: false, },
    { name: 'Hosszú megnevezés', align: 'left', hidden: false, },
    { name: 'NAV megnevezés', align: 'left', hidden: false, },
    { name: 'Bontható', align: 'center', hidden: false, },
    { name: 'Létrehozva', align: 'center', hidden: false, },
    { name: 'Módosítva', align: 'center', hidden: false, },
    { name: 'Létrehozó azonosító', align: 'right', hidden: true, },
    { name: 'Létrehozó felhasználónév', align: 'left', hidden: false, },
    { name: 'Módosító azonosító', align: 'right', hidden: true, },
    { name: 'Módosító felhasználónév', align: 'left', hidden: false, },
];

const navUnitOfMeasures: string[] = ['PIECE', 'KILOGRAM', 'TON', 'KWH', 'DAY', 'HOUR', 'MINUTE', 'MONTH', 'LITER', 'KILOMETER', 'CUBIC_METER', 'METER', 'LINEAR_METER', 'CARTON', 'PACK', 'OWN'];

const isDecompasableStates: NamedBooleanModel[] = [
    { name: 'Nem bontható', value: false, },
    { name: 'Bontható', value: true, },
];

interface State {
    dialogState: DialogState;
    setterState: UnitOfMeasureAddOrUpdateRequestModel;
    filterState: UnitOfMeasureFilterRequestModel;
    unitOfMeasures: UnitOfMeasureEntity[];
    refresh: boolean;
    users: UserEntity[];
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

interface DialogState {
    isOpened: boolean;
    type: DialogTypeEnumerator;
    id: number;
}

const initialState: State = {
    dialogState: {
        isOpened: false,
        type: DialogTypeEnumerator.Add,
        id: -1,
    },
    setterState: initialUnitOfMeasureAddOrUpdateRequestModel,
    filterState: initialUnitOfMeasureFilterRequestModel,
    unitOfMeasures: [],
    refresh: false,
    users: [],
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

const UnitOfMeasuresPage = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);
    const [debouncedState]: [UnitOfMeasureFilterRequestModel, DebouncedState<(value: UnitOfMeasureFilterRequestModel) => void>] = useDebounce<UnitOfMeasureFilterRequestModel>(state.filterState, debounceDelay);

    useEffect((): void => {
        const getUnitOfMeasures = async (): Promise<void> => {
            const filterData: UnitOfMeasureFilterRequestModel = {
                id: debouncedState.id! < 1 ? undefined : debouncedState.id,
                createdAtStart: debouncedState.createdAtStart === null ? undefined : debouncedState.createdAtStart,
                createdAtEnd: debouncedState.createdAtEnd === null ? undefined : debouncedState.createdAtEnd,
                updatedAtStart: debouncedState.updatedAtStart === null ? undefined : debouncedState.updatedAtStart,
                updatedAtEnd: debouncedState.updatedAtEnd === null ? undefined : debouncedState.updatedAtEnd,
                createdBy: debouncedState.createdBy,
                updatedBy: debouncedState.updatedBy,
                shortName: debouncedState.shortName?.length === 0 ? undefined : debouncedState.shortName,
                longName: debouncedState.longName?.length === 0 ? undefined : debouncedState.longName,
                navName: debouncedState.navName === null ? undefined : debouncedState.navName,
                isDecompasable: debouncedState.isDecompasable === null ? undefined : debouncedState.isDecompasable,
            }

            const paginationData: PaginationRequestModel = {
                currentPage: state.page + 1,
                dataPerPage: state.rowsPerPage,
            }

            const requestData: UnitOfMeasureListRequestModel = {
                filter: filterData,
                orderBy: 'shortName_asc',
                pagination: paginationData,
            }

            const response: ResponseModel<UnitOfMeasureEntity[]> = await UnitOfMeasureService.getUnitOfMeasures(requestData);

            if (response.status === 200) {
                const responseUnitOfMeasure: UnitOfMeasureEntity[] = response.data as UnitOfMeasureEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    unitOfMeasures: responseUnitOfMeasure,
                    rowCount: responseUnitOfMeasure.length,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getUnitOfMeasures();
    }, [
        debouncedState.createdAtEnd,
        debouncedState.createdAtStart,
        debouncedState.createdBy,
        debouncedState.id,
        debouncedState.isDecompasable,
        debouncedState.longName,
        debouncedState.navName,
        debouncedState.shortName,
        debouncedState.updatedAtEnd,
        debouncedState.updatedAtStart,
        debouncedState.updatedBy,
        state.page,
        state.refresh,
        state.rowsPerPage,
    ]);

    useEffect((): void => {
        const getUsers = async (): Promise<void> => {
            const requestData: UserListRequestModel = {
                orderBy: 'username_asc',
            }

            const response: ResponseModel<UserEntity[]> = await UserService.getUsers(requestData);

            if (response.status === 200) {
                const responseUser: UserEntity[] = response.data as UserEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    users: responseUser,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getUsers();
    }, []);

    const getNavUnitOfMeasureByNavNameFromState = useCallback((navName?: string | null): string | null => {
        return navUnitOfMeasures.find((value: string): boolean => value === navName) ?? null;
    }, []);

    const getUserByIdFromState = useCallback((id?: number | null): UserEntity | null => {
        return state.users.find((value: UserEntity): boolean => value.id === id) ?? null;
    }, [state.users]);

    const getNamedBooleanByIdFromState = useCallback((id?: boolean | null): NamedBooleanModel | null => {
        return isDecompasableStates.find((value: NamedBooleanModel): boolean => value.value === id) ?? null;
    }, []);

    const clearDialogState = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: initialState.dialogState,
            setterState: initialState.setterState,
        }));
    }, []);

    const validateModifiedData = useCallback((): boolean => {
        if (
            (!state.setterState.shortName)
            || ((state.setterState.shortName)
                && (state.setterState.shortName.length === 0))
        ) {
            toast.error('"Rövid megnevezés" mező kitöltése kötelező.');
            return false;
        }

        if (
            (!state.setterState.longName)
            || ((state.setterState.longName)
                && (state.setterState.longName.length === 0))
        ) {
            toast.error('"Hosszú megnevezés" mező kitöltése kötelező.');
            return false;
        }

        if (
            (!state.setterState.navName)
            || ((state.setterState.navName)
                && (state.setterState.navName.length === 0))
        ) {
            toast.error('"NAV megnevezés" érték kiválasztása kötelező.');
            return false;
        }

        return true;
    }, [
        state.setterState.longName,
        state.setterState.navName,
        state.setterState.shortName,
    ]);

    const addData = useCallback(async (): Promise<boolean> => {
        if (!validateModifiedData()) {
            return false;
        }

        const requestData: UnitOfMeasureAddOrUpdateRequestModel = {
            shortName: state.setterState.shortName,
            longName: state.setterState.longName,
            navName: state.setterState.navName,
            isDecompasable: state.setterState.isDecompasable,
        }

        const response: ResponseModel<UnitOfMeasureEntity> = await UnitOfMeasureService.addUnitOfMeasure(requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.setterState.isDecompasable,
        state.setterState.longName,
        state.setterState.navName,
        state.setterState.shortName,
        validateModifiedData,
    ]);

    const updateData = useCallback(async (): Promise<boolean> => {
        if (!validateModifiedData()) {
            return false;
        }

        const requestData: UnitOfMeasureAddOrUpdateRequestModel = {
            shortName: state.setterState.shortName,
            longName: state.setterState.longName,
            navName: state.setterState.navName,
            isDecompasable: state.setterState.isDecompasable,
        }

        const response: ResponseModel<UnitOfMeasureEntity> = await UnitOfMeasureService.updateUnitOfMeasure(state.dialogState.id, requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.dialogState.id,
        state.setterState.isDecompasable,
        state.setterState.longName,
        state.setterState.navName,
        state.setterState.shortName,
        validateModifiedData,
    ]);

    const removeData = useCallback(async (): Promise<void> => {
        const response: ResponseModel<string> = await UnitOfMeasureService.removeUnitOfMeasure(state.dialogState.id);

        if (response.status === 200) {
            setState((prevState: State): State => ({
                ...prevState,
                refresh: !prevState.refresh,
            }));
        } else {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }
        }
    }, [state.dialogState.id]);

    // --------------------------------------------------------------Events---
    const handleClickAddButton = useCallback((): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Add,
            },
        }));
    }, [clearDialogState]);

    const handleClickUpdateButton = useCallback((id: number, shortName: string, longName: string, navName: string, isDecompasable: boolean): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Update,
                id: id,
            },
            setterState: {
                shortName: shortName,
                longName: longName,
                navName: getNavUnitOfMeasureByNavNameFromState(navName),
                isDecompasable: isDecompasable,
            },
        }));
    }, [getNavUnitOfMeasureByNavNameFromState]);

    const handleClickRemoveButton = useCallback((id: number, shortName: string): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Remove,
                id: id,
            },
            setterState: {
                shortName: shortName,
            },
        }));
    }, [clearDialogState]);

    const handleClickCloseDialog = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: false,
            },
        }));
    }, []);

    const handleClickSaveButtonFromDialog = useCallback(async (): Promise<void> => {
        let isSaved: boolean = true;

        switch (state.dialogState.type) {
            case DialogTypeEnumerator.Add: {
                isSaved = await addData();
                break;
            }
            case DialogTypeEnumerator.Update: {
                isSaved = await updateData();
                break;
            }
            case DialogTypeEnumerator.Remove: {
                await removeData();
                break;
            }
            default: {
                break;
            }
        }

        if (!isSaved) {
            return;
        }

        clearDialogState();
        handleClickCloseDialog();

        toast.success('Sikeres ' + state.dialogState.type.toLocaleLowerCase() + '.');
    }, [
        addData,
        clearDialogState,
        state.dialogState.type,
        handleClickCloseDialog,
        removeData,
        updateData,
    ]);

    const handleChangeShortNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                shortName: event.target.value,
            },
        }));
    }, []);

    const handleChangeLongNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                longName: event.target.value,
            },
        }));
    }, []);

    const handleChangeNavNameDialog = useCallback((event: SyntheticEvent, newValue: string | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                navName: newValue,
            },
        }));
    }, []);

    const handleChangeIsDecompasableDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                isDecompasable: event.target.checked,
            },
        }));
    }, []);

    const handleChangeCreatedAtStartFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdAtStart: event,
            },
        }));
    }, []);

    const handleChangeCreatedAtEndFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdAtEnd: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtStartFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedAtStart: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtEndFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedAtEnd: event,
            },
        }));
    }, []);

    const handleChangeCreatedByFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeUpdatedByFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeIdFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                id: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeShortNameFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                shortName: event.target.value,
            },
        }));
    }, []);

    const handleChangeLongNameFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                longName: event.target.value,
            },
        }));
    }, []);

    const handleChangeNavNameFilter = useCallback((event: SyntheticEvent, newValue: string | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                navName: newValue,
            },
        }));
    }, []);

    const handleChangeIsDecompasableFilter = useCallback((event: SyntheticEvent, newValue: NamedBooleanModel | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                isDecompasable: newValue?.value,
            },
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            page: newPage,
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            rowsPerPage: Number(event.target.value),
            page: 0,
        }));
    }, []);

    // --------------------------------------------------------------Return---
    return (
        <>
            <Paper sx={PaperSxProps(theme).filter}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>
                            Szűrés
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LocalizationProvider adapterLocale="hu" dateAdapter={AdapterMoment}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Létrehozva kezdet" onChange={handleChangeCreatedAtStartFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.createdAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Létrehozva vég" onChange={handleChangeCreatedAtEndFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.createdAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Módosítva kezdet" onChange={handleChangeUpdatedAtStartFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.updatedAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Módosítva vég" onChange={handleChangeUpdatedAtEndFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.updatedAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeCreatedByFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Létrehozó felhasználó" />}
                                        value={getUserByIdFromState(state.filterState.createdBy)}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeUpdatedByFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Módosító felhasználó" />}
                                        value={getUserByIdFromState(state.filterState.updatedBy)}
                                    />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label="Azonosító" onChange={handleChangeIdFilter} type="number" value={state.filterState.id} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Rövid megnevezés" onChange={handleChangeShortNameFilter} type="text" value={state.filterState.shortName} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Hosszú megnevezés" onChange={handleChangeLongNameFilter} type="text" value={state.filterState.longName} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        onChange={handleChangeNavNameFilter}
                                        options={navUnitOfMeasures}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="NAV megnevezés" />}
                                        value={getNavUnitOfMeasureByNavNameFromState(state.filterState.navName)}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: NamedBooleanModel) => option.name ?? ''}
                                        onChange={handleChangeIsDecompasableFilter}
                                        options={isDecompasableStates}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Bontható" />}
                                        value={getNamedBooleanByIdFromState(state.filterState.isDecompasable)}
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </AccordionDetails>
                </Accordion>
            </Paper>

            <Paper>
                <TableContainer>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns
                                    .filter((column: TableColumnModel): boolean => (!column.hidden) || (AuthenticationService.adminState()))
                                    .map((column: TableColumnModel, index: number): JSX.Element => (
                                        <TableCell align={column.align} key={index}>
                                            <TableSortLabel>
                                                {column.name}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                }
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.unitOfMeasures.map((unitOfMeasure: UnitOfMeasureEntity, index: number): JSX.Element => {
                                return (
                                    <TableRow key={index} sx={TableRowSxProps(theme).bodyRow}>
                                        {((!columns[0].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[0].align}>{unitOfMeasure.id!.toLocaleString()}</TableCell>)}
                                        {((!columns[1].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[1].align}>{unitOfMeasure.shortName!}</TableCell>)}
                                        {((!columns[2].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[2].align}>{unitOfMeasure.longName!}</TableCell>)}
                                        {((!columns[3].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[3].align}>{unitOfMeasure.navName!}</TableCell>)}
                                        {((!columns[4].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[4].align}>{unitOfMeasure.isDecompasable! ? <Chip color="success" label="igen" /> : <Chip color="error" label="nem" />}</TableCell>)}
                                        {((!columns[5].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[5].align}>{moment(unitOfMeasure.createdAt!).format(dateTimeFormat)}</TableCell>)}
                                        {((!columns[6].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[6].align}>{moment(unitOfMeasure.updatedAt!).format(dateTimeFormat)}</TableCell>)}
                                        {((!columns[7].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[7].align}>{unitOfMeasure.createdBy!}</TableCell>)}
                                        {((!columns[8].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[8].align}>{unitOfMeasure.createdByUser!.username!}</TableCell>)}
                                        {((!columns[9].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[9].align}>{unitOfMeasure.updatedBy!}</TableCell>)}
                                        {((!columns[10].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[10].align}>{unitOfMeasure.updatedByUser!.username!}</TableCell>)}
                                        <TableCell align="center"><Fab color="warning" onClick={(): void => handleClickUpdateButton(unitOfMeasure.id!, unitOfMeasure.shortName!, unitOfMeasure.longName!, unitOfMeasure.navName!, unitOfMeasure.isDecompasable!)} size="small"><Settings /></Fab></TableCell>
                                        <TableCell align="center"><Fab color="error" onClick={(): void => handleClickRemoveButton(unitOfMeasure.id!, unitOfMeasure.shortName!)} size="small"><Delete /></Fab></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination component="div" count={state.rowCount} onPageChange={handlePageChange} onRowsPerPageChange={handleRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />
            </Paper>

            <Fab aria-label="add" color="success" onClick={handleClickAddButton} style={fabStyles.add}><Add /></Fab>

            <DialogComponent
                dataToRemoved={state.setterState.shortName ?? ''}
                dialogType={state.dialogState.type}
                fields={
                    <>
                        <TextField autoFocus fullWidth inputProps={{ maxLength: 10, }} label="Rövid megnevezés" onChange={handleChangeShortNameDialog} type="text" value={state.setterState.shortName} />
                        <TextField fullWidth inputProps={{ maxLength: 50, }} label="Hosszú megnevezés" onChange={handleChangeLongNameDialog} type="text" value={state.setterState.longName} />
                        <Autocomplete
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            onChange={handleChangeNavNameDialog}
                            options={navUnitOfMeasures}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="NAV megnevezés" />}
                            value={getNavUnitOfMeasureByNavNameFromState(state.setterState.navName)}
                        />
                        <FormControlLabel control={<Switch checked={state.setterState.isDecompasable} onChange={handleChangeIsDecompasableDialog} />} label={state.setterState.isDecompasable ? 'Bontható' : 'Nem bontható'} />
                    </>
                }
                isOpened={state.dialogState.isOpened}
                handleClickClose={handleClickCloseDialog}
                handleClickSave={handleClickSaveButtonFromDialog}
            />
        </>
    );
}

// -----------------------------------------------------------------Exports---
export default UnitOfMeasuresPage;
