// -----------------------------------------------------------------Imports---
import { StrictMode } from 'react';

import {
    createRoot,
    Root,
} from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------Privates---
const root: Root = createRoot(document.getElementById('root')!);

// ------------------------------------------------------------------Render---
root.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
);

reportWebVitals();
