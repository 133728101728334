// -----------------------------------------------------------------Imports---
import { Theme } from '@mui/material';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import SxPropsModel from '../../models/SxPropsModel';

// -----------------------------------------------------------------Exports---
export const dialogActionsStyles: CSSPropertiesModel = {
    main: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}

export const dialogContentStyles: CSSPropertiesModel = {
    main: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
}

export const DialogSxProps = (theme: Theme): SxPropsModel => {
    return {
        main: {
            maxWidth: 'unset',
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }
}

export const dialogTitleStyles: CSSPropertiesModel = {
    main: {
        textAlign: 'center',
    },
}
