// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import {
    Location,
    NavigateFunction,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    ExpandLess,
    ExpandMore,
    Remove,
} from '@mui/icons-material';

import {
    Collapse,
    CSSObject,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Theme,
    useTheme,
} from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';

import {
    listItemButtonSxProps,
    listItemIconSxProps,
    listItemTextSxProps,
    listItemStyles,
    ListSxProps,
} from './DrawerComponent.style';

import { menus } from '../../Global';

import MenuModel from '../../models/MenuModel';

// ----------------------------------------------------------------Privates---
let drawerWidth: number = 0;

const openedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: drawerWidth,
});

const closedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: 'calc(' + theme.spacing(7) + ' + 1px)',
    [theme.breakpoints.up('sm')]: {
        width: 'calc(' + theme.spacing(8) + ' + 1px)',
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: PropertyKey): boolean => prop !== 'open' })(({ theme, open }) => ({
    boxSizing: 'border-box',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth,
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface Props {
    isOpened: boolean;
    setIsOpened: Dispatch<SetStateAction<boolean>>;
    width: number;
}

interface State {
    selectedMenuIndex: number;
}

const initialState: State = {
    selectedMenuIndex: -1,
}

const DrawerComponent = ({ isOpened, setIsOpened, width }: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const { pathname }: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);

    useEffect((): void => {
        drawerWidth = width;
    }, [width]);

    // --------------------------------------------------------------Events---
    const handleClickMenu = useCallback((index: number, route: string | undefined): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selectedMenuIndex: index !== state.selectedMenuIndex
                ? index
                : !isOpened
                    ? state.selectedMenuIndex
                    : -1,
        }));

        if (!isOpened) {
            setIsOpened(true);
        }

        if (route !== undefined) {
            setIsOpened(false);
            navigate(route);
        }
    }, [navigate, isOpened, setIsOpened, state.selectedMenuIndex]);

    const handleClickSubMenu = useCallback((route: string): void => {
        setIsOpened(false);
        navigate(route);
    }, [navigate, setIsOpened]);

    // --------------------------------------------------------------Return---
    return (
        <Drawer open={isOpened} variant="permanent">
            <List component="nav" disablePadding key="menu" sx={ListSxProps(theme).main}>
                {menus.map((menu: MenuModel, index: number): JSX.Element => {
                    return (
                        <ListItem disablePadding key={index} style={listItemStyles.main}>
                            <ListItemButton onClick={(): void => handleClickMenu(index, menu.route!)} selected={(menu.route !== undefined) && (pathname === menu.route)} sx={listItemButtonSxProps(isOpened).main}>
                                <ListItemIcon sx={listItemIconSxProps(isOpened).main}>
                                    {menu.icon}
                                </ListItemIcon>
                                <ListItemText primary={menu.name} sx={listItemTextSxProps(isOpened).main} />
                                {
                                    (isOpened) && (menu.route === undefined) && (
                                        (menu.subMenu === undefined)
                                            ? <Remove />
                                            :
                                            (index === state.selectedMenuIndex)
                                                ? <ExpandLess />
                                                : <ExpandMore />
                                    )
                                }
                            </ListItemButton>

                            <Collapse in={(isOpened) && (index === state.selectedMenuIndex)} timeout="auto" unmountOnExit>
                                <List disablePadding key={'subMenu' + index}>
                                    {(menu.subMenu !== undefined) && menu.subMenu!.map((subMenu: MenuModel, subIndex: number): JSX.Element => {
                                        return (
                                            <ListItem disablePadding key={subIndex} style={listItemStyles.main}>
                                                <ListItemButton onClick={(): void => handleClickSubMenu(subMenu.route!)} selected={pathname === subMenu.route} sx={listItemButtonSxProps(isOpened).sub}>
                                                    <ListItemIcon sx={listItemIconSxProps(isOpened).sub}>
                                                        {subMenu.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={subMenu.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </ListItem>
                    );
                })}
            </List>
        </Drawer>
    );
}

// -----------------------------------------------------------------Exports---
export default DrawerComponent;
