// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    DebouncedState,
    useDebounce,
} from 'use-debounce';

import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@mui/material';

import { nameSort } from '../../../CodeKit';
import { debounceDelay } from '../../../Global';

import GroupEntity from '../../../entities/GroupEntity';
import ProductEntity from '../../../entities/ProductEntity';
import ServiceListEntity from '../../../entities/ServiceListEntity';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';
import FieldValidationErrorModel from '../../../models/FieldValidationErrorModel';
import ResponseModel from '../../../models/ResponseModel';
import ProductFilterRequestModel from '../../../models/product/ProductFilterRequestModel';
import ProductListRequestModel from '../../../models/product/ProductListRequestModel';
import ServiceListFilterRequestModel from '../../../models/serviceList/ServiceListFilterRequestModel';
import ServiceListListRequestModel from '../../../models/serviceList/ServiceListListRequestModel';

import GroupService from '../../../services/group/GroupService';
import ProductService from '../../../services/product/ProductService';
import ServiceListService from '../../../services/serviceList/ServiceListService';

// ----------------------------------------------------------------Privates---
interface FilterState {
    barcode: string;
    name: string;
    groupId?: number;
}

interface State {
    filterState: FilterState;
    groups: GroupEntity[];
    products: (ProductEntity | ServiceListEntity)[];
}

const initialState: State = {
    filterState: {
        barcode: '',
        name: '',
        groupId: undefined,
    },
    groups: [],
    products: [],
}

const SaleInvoicePage = (): JSX.Element => {
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);
    const [debouncedState]: [FilterState, DebouncedState<(value: FilterState) => void>] = useDebounce<FilterState>(state.filterState, debounceDelay);

    useEffect((): void => {
        const filterData: ProductFilterRequestModel | ServiceListFilterRequestModel = {
            barcode: debouncedState.barcode?.length === 0 ? undefined : debouncedState.barcode,
            name: debouncedState.name?.length === 0 ? undefined : debouncedState.name,
            groupId: state.filterState.groupId,
        }

        const requestData: ProductListRequestModel | ServiceListListRequestModel = {
            filter: filterData,
        }

        const getProducts = async (): Promise<ProductEntity[]> => {
            const response: ResponseModel<ProductEntity[]> = await ProductService.getProducts(requestData);

            if (response.status === 200) {
                const responseProduct: ProductEntity[] = response.data as ProductEntity[];

                return responseProduct;
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }

                return [];
            }
        }

        const getServiceLists = async (): Promise<ServiceListEntity[]> => {
            const response: ResponseModel<ServiceListEntity[]> = await ServiceListService.getServiceLists(requestData);

            if (response.status === 200) {
                const responseServiceList: ServiceListEntity[] = response.data as ServiceListEntity[];

                return responseServiceList;
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }

                return [];
            }
        }

        const fetchData = async (): Promise<void> => {
            let tempProducts: (ProductEntity | ServiceListEntity)[] = [];

            tempProducts.push(...await getProducts());
            tempProducts.push(...await getServiceLists());

            tempProducts = tempProducts.sort((valueA: ProductEntity | ServiceListEntity, valueB: ProductEntity | ServiceListEntity): number => {
                return nameSort(valueA.name!, valueB.name!);
            });

            setState((prevState: State): State => ({
                ...prevState,
                products: tempProducts,
            }));
        };

        fetchData();
    }, [
        debouncedState.barcode,
        debouncedState.name,
        state.filterState.groupId,
    ]);

    useEffect((): void => {
        const getGroups = async (): Promise<void> => {
            const response: ResponseModel<GroupEntity[]> = await GroupService.getGroups();

            if (response.status === 200) {
                const responseGroup: GroupEntity[] = response.data as GroupEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    groups: responseGroup,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getGroups();
    }, []);

    // --------------------------------------------------------------Events---
    const handleClickGroupFilter = useCallback((groupId?: number): void => {
        if (groupId === state.filterState.groupId) {
            groupId = undefined;
        }

        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                groupId: groupId,
            }
        }));
    }, [state.filterState.groupId]);

    const handleChangeBarcodeFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                barcode: event.target.value,
            }
        }));
    }, []);

    const handleChangeNameFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                name: event.target.value,
            }
        }));
    }, []);

    // --------------------------------------------------------------Styles---
    const buttonStyles: CSSPropertiesModel = {
        noWrap: {
            whiteSpace: 'nowrap',
        },
    }

    const cardStyles: CSSPropertiesModel = {
        main: {
            height: '100%',
            width: '100%',
        },
    }

    const cardActionAreaStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
        },
    }

    const cardHeader: CSSPropertiesModel = {
        main: {
            height: '100%',
        },
    }

    const dividerStyles: CSSPropertiesModel = {
        main: {
            marginBottom: 5,
            marginTop: 5,
        },
    }

    const gridStyles: CSSPropertiesModel = {
        containerColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        containerRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        containerRowOverflowX: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            overflowX: 'auto',
        },
        containerRowOverflowY: {
            display: 'flex',
            flexDirection: 'row',
            overflowY: 'auto',
        },
        itemRow: {
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'row',
        },
    }

    const typographyStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <Grid container spacing={1} style={gridStyles.containerRow}>
                <Grid item lg={9} md={8} sm={6} xs={12}>
                    <Grid container spacing={1} style={gridStyles.containerColumn}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} style={gridStyles.containerRowOverflowX}>
                                {state.groups.map((group: GroupEntity, index: number): JSX.Element => {
                                    return (
                                        <Grid item key={index} lg={3} md={4} sm={6} xs={12}>
                                            <Button color={state.filterState.groupId === group.id ? "success" : "info"} fullWidth onClick={() => handleClickGroupFilter(group.id)} style={buttonStyles.noWrap} variant="contained">
                                                {group.name}
                                            </Button>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} style={gridStyles.containerRow}>
                                <Grid item xs={6}>
                                    <TextField fullWidth label="Vonalkód" onChange={handleChangeBarcodeFilter} value={state.filterState.barcode} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth label="Megnevezés" onChange={handleChangeNameFilter} value={state.filterState.name} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} style={gridStyles.containerRowOverflowY}>
                                {state.products.map((product: ProductEntity, index: number): JSX.Element => {
                                    return (
                                        <Grid item key={index} style={gridStyles.itemRow} lg={2} md={3} sm={6} xs={12}>
                                            <Card style={cardStyles.main}>
                                                <CardActionArea style={cardActionAreaStyles.main}>
                                                    <CardMedia alt={product.barcode} component="img" image={require('../../../images/notFound.png')} />
                                                    <Divider style={dividerStyles.main} variant="middle" />
                                                    <CardHeader title={product.name} style={cardHeader.main} />
                                                    <CardContent>
                                                        <Typography style={typographyStyles.main}>
                                                            {product.grossSalesPrice} Ft
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} style={gridStyles.containerRow}>
                                <Grid item xs={4}>
                                    <TextField fullWidth InputProps={{ readOnly: true, }} label="Nettó összes" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth InputProps={{ readOnly: true, }} label="ÁFA összes" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth InputProps={{ readOnly: true, }} label="Bruttó összes" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Grid container spacing={1} style={gridStyles.containerColumn}>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Kiválasztott tételek
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Szállítási mód
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Fizetési mód
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Partner
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Megjegyzés
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Teljesítés időbélyeg
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" fullWidth variant="contained">
                                Rögzítés
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

// -----------------------------------------------------------------Exports---
export default SaleInvoicePage;
