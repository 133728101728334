// -----------------------------------------------------------------Imports---
import { Theme } from '@mui/material';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import SxPropsModel from '../../models/SxPropsModel';

// -----------------------------------------------------------------Exports---
export const AppBarSxProps = (theme: Theme): SxPropsModel => {
    return {
        appBar: {
            position: 'sticky',
            zIndex: theme.zIndex.drawer + 1,
        },
    }
}

export const gridStyles: CSSPropertiesModel = {
    container: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    item: {
        alignItems: 'center',
        display: 'flex',
    },
}

export const typographySxProps = (): SxPropsModel => {
    return {
        pageName: {
            display: {
                sm: 'unset',
                xs: 'none',
            },
            whiteSpace: 'nowrap',
        },
        productName: {
            display: {
                md: 'unset',
                xs: 'none',
            },
            whiteSpace: 'nowrap',
        },
    }
}
