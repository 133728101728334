// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
} from 'react';

import {
    Location,
    NavigateFunction,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    DarkMode,
    LightMode,
    Logout,
    Menu,
} from '@mui/icons-material';

import {
    AppBar,
    Avatar,
    Grid,
    IconButton,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';

import {
    AppBarSxProps,
    gridStyles,
    typographySxProps,
} from './HeaderComponent.style';

import { getNameFromRoute } from '../../CodeKit';

import {
    getAppRoutes,
    isTest
} from '../../Global';

import AuthenticationService from '../../services/authentication/AuthenticationService';

// ----------------------------------------------------------------Privates---
interface Props {
    setIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
    isDarkMode: boolean;
    setIsDarkMode: Dispatch<SetStateAction<boolean>>;
}

const HeaderComponent = ({ setIsDrawerOpened, isDarkMode, setIsDarkMode }: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const { pathname }: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const theme: Theme = useTheme<Theme>();

    const currentPageName: string = useMemo((): string => getNameFromRoute(pathname), [pathname]);

    const isTestModeMark: string = useMemo((): string => isTest ? '[TESZT]' : '', []);

    // --------------------------------------------------------------Events---
    const handleClickMenuOpen = useCallback((): void => {
        setIsDrawerOpened((prevOpen: boolean): boolean => !prevOpen);
    }, [setIsDrawerOpened]);

    const handleClickLogout = useCallback((): void => {
        AuthenticationService.logout();
        toast.success('Sikeres kijelentkezés.');
        navigate(getAppRoutes.login);
    }, [navigate]);

    const handleClickDarkMode = useCallback((): void => {
        setIsDarkMode((prevIsDarkMode: boolean): boolean => !prevIsDarkMode);
    }, [setIsDarkMode]);

    const handleClickUser = useCallback((): void => {
        navigate(getAppRoutes.user);
    }, [navigate]);

    // --------------------------------------------------------------Return---
    return (
        <AppBar component="header" sx={AppBarSxProps(theme).appBar}>
            <Toolbar>
                <Grid container style={gridStyles.container}>
                    <Grid item style={gridStyles.item}>
                        <IconButton aria-label="open drawer" edge="start" onClick={handleClickMenuOpen}>
                            <Menu />
                        </IconButton>
                        <Typography sx={typographySxProps().productName} variant="h6">
                            SziHome StockWatch {isTestModeMark}
                        </Typography>
                    </Grid>
                    <Grid item style={gridStyles.item}>
                        <Typography sx={typographySxProps().pageName} variant="h6">
                            {currentPageName}
                        </Typography>
                    </Grid>
                    <Grid item style={gridStyles.item}>
                        <IconButton onClick={handleClickLogout}>
                            <Logout />
                        </IconButton>
                        <IconButton onClick={handleClickDarkMode}>
                            {isDarkMode ? <DarkMode /> : <LightMode />}
                        </IconButton>
                        <Tooltip title="SziHome felhasználó">
                            <IconButton onClick={handleClickUser}>
                                <Avatar alt="SziHome felhasználó" src="/" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

// -----------------------------------------------------------------Exports---
export default HeaderComponent;
