// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import PartnerEntity from '../../entities/PartnerEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import PartnerAddOrUpdateRequestModel from '../../models/partner/PartnerAddOrUpdateRequestModel';
import PartnerListRequestModel from '../../models/partner/PartnerListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getPartners(data?: PartnerListRequestModel): Promise<ResponseModel<PartnerEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.partner.getPartners,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async addPartner(data: PartnerAddOrUpdateRequestModel): Promise<ResponseModel<PartnerEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.partner.addPartner,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async updatePartner(id: number, data?: PartnerAddOrUpdateRequestModel): Promise<ResponseModel<PartnerEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.partner.updatePartner.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }

    async removePartner(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.partner.removePartner.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        );
    }
}

const PartnerService = new LocalService();

// -----------------------------------------------------------------Exports---
export default PartnerService;
