// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import UserEntity from '../../entities/UserEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import UserListRequestModel from '../../models/user/UserListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getUsers(data?: UserListRequestModel): Promise<ResponseModel<UserEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.user.getUsers,
            'POST',
            {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data,
        );
    }
}

const UserService = new LocalService();

// -----------------------------------------------------------------Exports---
export default UserService;
