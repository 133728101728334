// -----------------------------------------------------------------Imports---
import {
    menus,
    roundingDigits,
} from './Global';

import MenuModel from './models/MenuModel';

// -----------------------------------------------------------------Exports---
export const getNameFromRoute = (route: string): string => {
    const matchedMenu: MenuModel | undefined = menus.find((menu: MenuModel): boolean | undefined => menu.route === route || menu.subMenu?.some((subMenu: MenuModel): boolean => subMenu.route === route));

    if (!matchedMenu) {
        return '';
    }

    const displayName: string = matchedMenu.name + (matchedMenu.subMenu ? ' / ' + matchedMenu.subMenu.find((subMenu: MenuModel): boolean => subMenu.route === route)?.name : '');

    return displayName;
}

export const grossFromNet = (net?: number, vatValue?: number): number => {
    if (!net) {
        net = 0;
    }

    if (!vatValue) {
        vatValue = 0;
    }

    return net * (1 + (vatValue / 100));
}

export const nameSort = (valueA: string, valueB: string): number => {
    if (valueA === valueB) {
        return 0;
    } else if (valueA < valueB) {
        return -1;
    } else {
        return 1;
    }
}

export const netFromGross = (gross?: number, vatValue?: number): number => {
    if (!gross) {
        gross = 0;
    }

    if (!vatValue) {
        vatValue = 0;
    }

    return gross / (1 + (vatValue / 100));
}

export const numberInStringIsValid = (value: string): boolean => {
    return !isNaN(Number(value));
}

export const roundTo = (value: number): number => {
    const factor: number = 10 ** roundingDigits;

    return Math.round(value * factor) / factor;
}
