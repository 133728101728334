// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Location,
    NavigateFunction,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    toast,
    ToastContainer,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import {
    Box,
    CssBaseline,
    Theme,
    ThemeProvider,
    useTheme,
} from '@mui/material';

import {
    boxStyles,
    BoxSxProps,
    globalTheme,
} from './App.style';

import { getAppRoutes } from './Global';

import DrawerComponent from './components/drawer/DrawerComponent';
import HeaderComponent from './components/header/HeaderComponent';

import ThemeModeEnumerator from './enumerators/ThemeModeEnumerator';

import CountriesPage from './pages/countries/CountriesPage';
import GroupsPage from './pages/groups/GroupsPage';
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import PartnersPage from './pages/partners/PartnersPage';
import ProductsPage from './pages/products/ProductsPage';
import SaleInvoicePage from './pages/sale/invoice/SaleInvoicePage';
import ServiceListsPage from './pages/serviceLists/ServiceListsPage';
import UnitOfMeasuresPage from './pages/unitOfMeasures/UnitOfMeasuresPage';
import VatRatesPage from './pages/vatRates/VatRatesPage';

import AuthenticationService from './services/authentication/AuthenticationService';

// ----------------------------------------------------------------Privates---
const exceptPaths: string[] = [getAppRoutes.login];
const drawerWidth: number = 275;

const App = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const { pathname }: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const theme: Theme = useTheme<Theme>();
    const [isLoggedIn, setIsLoggedIn]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
    const [isDrawerOpened, setIsDrawerOpened]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
    const [isDarkMode, setIsDarkMode]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);

    const globalThemeMemo: Theme = useMemo((): Theme => globalTheme(isDarkMode ? ThemeModeEnumerator.Dark : ThemeModeEnumerator.Light), [isDarkMode]);

    useEffect((): void => {
        moment.updateLocale('hu', { week: { dow: 1 } });
    }, []);

    useEffect((): void => {
        const isLoggedInTemp: boolean = AuthenticationService.loggedInState();
        const shouldRedirectToHome: boolean = (isLoggedInTemp) && (exceptPaths.includes(pathname));
        const shouldRedirectToLogin: boolean = (!isLoggedInTemp) && (!exceptPaths.includes(pathname));

        setIsLoggedIn(isLoggedInTemp);

        if (shouldRedirectToHome) {
            navigate(getAppRoutes.home);
        } else if (shouldRedirectToLogin) {
            navigate(getAppRoutes.login);
        }
    }, [navigate, pathname]);

    // --------------------------------------------------------------Events---
    const handleError = useCallback((event: string | Event, source: string | undefined, lineno: number | undefined, colno: number | undefined, error: Error | undefined): void => {
        console.log(event);
        toast.error(event.toString());
    }, []);

    useEffect((): void => {
        window.onerror = handleError;
    }, [handleError]);

    // --------------------------------------------------------------Return---
    return (
        <ThemeProvider theme={globalThemeMemo}>
            <Box style={boxStyles.page}>
                <CssBaseline enableColorScheme />
                <ToastContainer pauseOnFocusLoss={false} position={'bottom-right'} theme={isDarkMode ? ThemeModeEnumerator.Dark : ThemeModeEnumerator.Light} />
                {(isLoggedIn) && (<HeaderComponent isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} setIsDrawerOpened={setIsDrawerOpened} />)}
                <Box sx={BoxSxProps(isDrawerOpened, drawerWidth, theme).subMain}>
                    {(isLoggedIn) && (<DrawerComponent isOpened={isDrawerOpened} setIsOpened={setIsDrawerOpened} width={drawerWidth} />)}
                    <Box component="main" sx={BoxSxProps(isDrawerOpened, drawerWidth, theme).main}>
                        <Routes>
                            <Route element={<CountriesPage />} path={getAppRoutes.masterData.countries} />
                            <Route element={<GroupsPage />} path={getAppRoutes.masterData.groups} />
                            <Route element={<HomePage />} path={getAppRoutes.home} />
                            <Route element={<LoginPage />} path={getAppRoutes.login} />
                            <Route element={<NotFoundPage />} path={getAppRoutes.notFound} />
                            <Route element={<PartnersPage />} path={getAppRoutes.masterData.partners} />
                            <Route element={<ProductsPage />} path={getAppRoutes.masterData.products} />
                            <Route element={<SaleInvoicePage />} path={getAppRoutes.sale.invoice} />
                            <Route element={<ServiceListsPage />} path={getAppRoutes.masterData.serviceLists} />
                            <Route element={<UnitOfMeasuresPage />} path={getAppRoutes.masterData.unitOfMeasures} />
                            <Route element={<VatRatesPage />} path={getAppRoutes.masterData.vatRates} />
                        </Routes>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

// -----------------------------------------------------------------Exports---
export default App;
