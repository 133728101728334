// -----------------------------------------------------------------Imports---
import axios, {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';

import ResponseModel from '../models/ResponseModel';

// ----------------------------------------------------------------Privates---
const SendWithAxios = async <T,>(
    url: string,
    method: AxiosRequestConfig['method'],
    headers?: AxiosRequestConfig['headers'],
    data?: AxiosRequestConfig['data'],
): Promise<T> => {
    const config: AxiosRequestConfig = {
        url: url,
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
        data: data,
    }

    return await axios(config);
}

const fetchData = async <T,>(
    url: string,
    method: AxiosRequestConfig['method'],
    header?: AxiosRequestConfig['headers'],
    data?: AxiosRequestConfig['data'],
): Promise<ResponseModel<T>> => {
    try {
        const response: AxiosResponse<ResponseModel<T>> = await SendWithAxios(url, method, header, data);

        return response.data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError: AxiosError<ResponseModel<T>> = error;

            if (axiosError.response) {
                return axiosError.response?.status === 401
                    ? {
                        status: axiosError.response?.status,
                        error: {
                            message: 'Sikertelen hitelesítés',
                        },
                    }
                    : axiosError.response?.data || {
                        status: 400,
                        error: {
                            message: 'Ismeretlen hiba történt...',
                        },
                    };
            }

            return {
                status: 400,
                error: {
                    message: axiosError.message + ' [' + axiosError.name + ', ' + axiosError.code + ']',
                }
            }
        }

        return {
            status: 500,
            error: {
                message: 'Hálózati hiba történt.',
            },
        };
    }
}

// -----------------------------------------------------------------Exports---
export default fetchData;
