// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    ChangeEvent,
    Dispatch,
    MouseEvent,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    DebouncedState,
    useDebounce
} from 'use-debounce';

import {
    Add,
    Delete,
    ExpandMore,
    Settings,
} from '@mui/icons-material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    AutocompleteRenderInputParams,
    Fab,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import {
    fabStyles,
    PaperSxProps,
    TableRowSxProps,
} from './GroupsPage.style';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    debounceDelay,
} from '../../Global';

import DialogComponent from '../../components/dialog/DialogComponent';

import GroupEntity from '../../entities/GroupEntity';
import UserEntity from '../../entities/UserEntity';

import DialogTypeEnumerator from '../../enumerators/DialogTypeEnumerator';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import PaginationRequestModel from '../../models/PaginationRequestModel';
import ResponseModel from '../../models/ResponseModel';
import TableColumnModel from '../../models/TableColumnModel';
import GroupAddOrUpdateRequestModel, { initialGroupAddOrUpdateRequestModel } from '../../models/group/GroupAddOrUpdateRequestModel';
import GroupFilterRequestModel, { initialGroupFilterRequestModel } from '../../models/group/GroupFilterRequestModel';
import GroupListRequestModel from '../../models/group/GroupListRequestModel';
import UserListRequestModel from '../../models/user/UserListRequestModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import GroupService from '../../services/group/GroupService';
import UserService from '../../services/user/UserService';

// ----------------------------------------------------------------Privates---
const columns: TableColumnModel[] = [
    { name: 'Azonosító', align: 'right', hidden: true, },
    { name: 'Megnevezés', align: 'left', hidden: false, },
    { name: 'Létrehozva', align: 'center', hidden: false, },
    { name: 'Módosítva', align: 'center', hidden: false, },
    { name: 'Létrehozó azonosító', align: 'right', hidden: true, },
    { name: 'Létrehozó felhasználónév', align: 'left', hidden: false, },
    { name: 'Módosító azonosító', align: 'right', hidden: true, },
    { name: 'Módosító felhasználónév', align: 'left', hidden: false, },
];

interface State {
    dialogState: DialogState;
    setterState: GroupAddOrUpdateRequestModel;
    filterState: GroupFilterRequestModel;
    groups: GroupEntity[];
    refresh: boolean;
    users: UserEntity[];
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

interface DialogState {
    isOpened: boolean;
    type: DialogTypeEnumerator;
    id: number;
}

const initialState: State = {
    dialogState: {
        isOpened: false,
        type: DialogTypeEnumerator.Add,
        id: -1,
    },
    setterState: initialGroupAddOrUpdateRequestModel,
    filterState: initialGroupFilterRequestModel,
    groups: [],
    refresh: false,
    users: [],
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

const GroupsPage = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);
    const [debouncedState]: [GroupFilterRequestModel, DebouncedState<(value: GroupFilterRequestModel) => void>] = useDebounce<GroupFilterRequestModel>(state.filterState, debounceDelay);

    useEffect((): void => {
        const getGroups = async (): Promise<void> => {
            const filterData: GroupFilterRequestModel = {
                id: debouncedState.id! < 1 ? undefined : debouncedState.id,
                createdAtStart: debouncedState.createdAtStart === null ? undefined : debouncedState.createdAtStart,
                createdAtEnd: debouncedState.createdAtEnd === null ? undefined : debouncedState.createdAtEnd,
                updatedAtStart: debouncedState.updatedAtStart === null ? undefined : debouncedState.updatedAtStart,
                updatedAtEnd: debouncedState.updatedAtEnd === null ? undefined : debouncedState.updatedAtEnd,
                createdBy: debouncedState.createdBy,
                updatedBy: debouncedState.updatedBy,
                name: debouncedState.name?.length === 0 ? undefined : debouncedState.name,
            }

            const paginationData: PaginationRequestModel = {
                currentPage: state.page + 1,
                dataPerPage: state.rowsPerPage,
            }

            const requestData: GroupListRequestModel = {
                filter: filterData,
                orderBy: 'name_asc',
                pagination: paginationData,
            }

            const response: ResponseModel<GroupEntity[]> = await GroupService.getGroups(requestData);

            if (response.status === 200) {
                const responseGroup: GroupEntity[] = response.data as GroupEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    groups: responseGroup,
                    rowCount: responseGroup.length,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getGroups();
    }, [
        debouncedState.createdAtEnd,
        debouncedState.createdAtStart,
        debouncedState.createdBy,
        debouncedState.id,
        debouncedState.name,
        debouncedState.updatedAtEnd,
        debouncedState.updatedAtStart,
        debouncedState.updatedBy,
        state.page,
        state.refresh,
        state.rowsPerPage,
    ]);

    useEffect((): void => {
        const getUsers = async (): Promise<void> => {
            const requestData: UserListRequestModel = {
                orderBy: 'username_asc',
            }

            const response: ResponseModel<UserEntity[]> = await UserService.getUsers(requestData);

            if (response.status === 200) {
                const responseUser: UserEntity[] = response.data as UserEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    users: responseUser,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        getUsers();
    }, []);

    const getUserByIdFromState = useCallback((id?: number | null): UserEntity | null => {
        return state.users.find((value: UserEntity): boolean => value.id === id) ?? null;
    }, [state.users]);

    const validateModifiedData = useCallback((): boolean => {
        if (
            (!state.setterState.name)
            || ((state.setterState.name)
                && (state.setterState.name.length === 0))
        ) {
            toast.error('"Megnevezés" mező kitöltése kötelező.');
            return false;
        }

        return true;
    }, [state.setterState.name]);

    const clearDialogState = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: initialState.dialogState,
            setterState: initialState.setterState,
        }));
    }, []);

    const addData = useCallback(async (): Promise<boolean> => {
        if (!validateModifiedData()) {
            return false;
        }

        const requestData: GroupAddOrUpdateRequestModel = {
            name: state.setterState.name,
        }

        const response: ResponseModel<GroupEntity> = await GroupService.addGroup(requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.setterState.name,
        validateModifiedData,
    ]);

    const updateData = useCallback(async (): Promise<boolean> => {
        if (!validateModifiedData()) {
            return false;
        }

        const requestData: GroupAddOrUpdateRequestModel = {
            name: state.setterState.name,
        }

        const response: ResponseModel<GroupEntity> = await GroupService.updateGroup(state.dialogState.id, requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }

            return false;
        }
        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.dialogState.id,
        state.setterState.name,
        validateModifiedData,
    ]);

    const removeData = useCallback(async (): Promise<void> => {
        const response: ResponseModel<string> = await GroupService.removeGroup(state.dialogState.id);

        if (response.status === 200) {
            setState((prevState: State): State => ({
                ...prevState,
                refresh: !prevState.refresh,
            }));
        } else {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(field.field + ': ' + field.message);
                });
            }
        }
    }, [state.dialogState.id]);

    // --------------------------------------------------------------Events---
    const handleClickAddButton = useCallback((): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Add,
            },
        }));
    }, [clearDialogState]);

    const handleClickUpdateButton = useCallback((id: number, name: string): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Update,
                id: id,
            },
            setterState: {
                name: name,
            },
        }));
    }, []);

    const handleClickRemoveButton = useCallback((id: number, name: string): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Remove,
                id: id,
            },
            setterState: {
                name: name,
            },
        }));
    }, [clearDialogState]);

    const handleClickCloseDialog = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: false,
            },
        }));
    }, []);

    const handleClickSaveButtonFromDialog = useCallback(async (): Promise<void> => {
        let isSaved: boolean = true;

        switch (state.dialogState.type) {
            case DialogTypeEnumerator.Add: {
                isSaved = await addData();
                break;
            }
            case DialogTypeEnumerator.Update: {
                isSaved = await updateData();
                break;
            }
            case DialogTypeEnumerator.Remove: {
                await removeData();
                break;
            }
            default: {
                break;
            }
        }

        if (!isSaved) {
            return;
        }

        clearDialogState();
        handleClickCloseDialog();

        toast.success('Sikeres ' + state.dialogState.type.toLocaleLowerCase() + '.');
    }, [
        addData,
        clearDialogState,
        state.dialogState.type,
        handleClickCloseDialog,
        removeData,
        updateData,
    ]);

    const handleChangeNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleChangeCreatedAtStartFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdAtStart: event,
            },
        }));
    }, []);

    const handleChangeCreatedAtEndFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdAtEnd: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtStartFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedAtStart: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtEndFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedAtEnd: event,
            },
        }));
    }, []);

    const handleChangeCreatedByFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeUpdatedByFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeIdFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                id: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeNameFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            page: newPage,
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            rowsPerPage: Number(event.target.value),
            page: 0,
        }));
    }, []);

    // --------------------------------------------------------------Return---
    return (
        <>
            <Paper sx={PaperSxProps(theme).filter}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>
                            Szűrés
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LocalizationProvider adapterLocale="hu" dateAdapter={AdapterMoment}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Létrehozva kezdet" onChange={handleChangeCreatedAtStartFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.createdAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Létrehozva vég" onChange={handleChangeCreatedAtEndFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.createdAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Módosítva kezdet" onChange={handleChangeUpdatedAtStartFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.updatedAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label="Módosítva vég" onChange={handleChangeUpdatedAtEndFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.updatedAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeCreatedByFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Létrehozó felhasználó" />}
                                        value={getUserByIdFromState(state.filterState.createdBy)}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeUpdatedByFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label="Létrehozó felhasználó" />}
                                        value={getUserByIdFromState(state.filterState.updatedBy)}
                                    />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label="Azonosító" onChange={handleChangeIdFilter} type="number" value={state.filterState.id} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label="Megnevezés" onChange={handleChangeNameFilter} type="text" value={state.filterState.name} />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </AccordionDetails>
                </Accordion>
            </Paper>

            <Paper>
                <TableContainer>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns
                                    .filter((column: TableColumnModel): boolean => (!column.hidden) || (AuthenticationService.adminState()))
                                    .map((column: TableColumnModel, index: number): JSX.Element => (
                                        <TableCell align={column.align} key={index}>
                                            <TableSortLabel>
                                                {column.name}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                }
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.groups.map((group: GroupEntity, index: number): JSX.Element => {
                                return (
                                    <TableRow key={index} sx={TableRowSxProps(theme).bodyRow}>
                                        {((!columns[0].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[0].align}>{group.id!.toLocaleString()}</TableCell>)}
                                        {((!columns[1].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[1].align}>{group.name!}</TableCell>)}
                                        {((!columns[2].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[2].align}>{moment(group.createdAt!).format(dateTimeFormat)}</TableCell>)}
                                        {((!columns[3].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[3].align}>{moment(group.updatedAt!).format(dateTimeFormat)}</TableCell>)}
                                        {((!columns[4].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[4].align}>{group.createdBy!}</TableCell>)}
                                        {((!columns[5].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[5].align}>{group.createdByUser!.username!}</TableCell>)}
                                        {((!columns[6].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[6].align}>{group.updatedBy!}</TableCell>)}
                                        {((!columns[7].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[7].align}>{group.updatedByUser!.username!}</TableCell>)}
                                        <TableCell align="center"><Fab color="warning" onClick={(): void => handleClickUpdateButton(group.id!, group.name!)} size="small"><Settings /></Fab></TableCell>
                                        <TableCell align="center"><Fab color="error" onClick={(): void => handleClickRemoveButton(group.id!, group.name!)} size="small"><Delete /></Fab></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination component="div" count={state.rowCount} onPageChange={handlePageChange} onRowsPerPageChange={handleRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />
            </Paper>

            <Fab aria-label="add" color="success" onClick={handleClickAddButton} style={fabStyles.add}><Add /></Fab>

            <DialogComponent
                dataToRemoved={state.setterState.name ?? ''}
                dialogType={state.dialogState.type}
                fields={
                    <>
                        <TextField autoFocus fullWidth inputProps={{ maxLength: 50, }} label="Megnevezés" onChange={handleChangeNameDialog} type="text" value={state.setterState.name} />
                    </>
                }
                isOpened={state.dialogState.isOpened}
                handleClickClose={handleClickCloseDialog}
                handleClickSave={handleClickSaveButtonFromDialog}
            />
        </>
    );
}

// -----------------------------------------------------------------Exports---
export default GroupsPage;
