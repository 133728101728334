// -----------------------------------------------------------------Imports---
import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Theme,
    useTheme,
} from '@mui/material';

import {
    dialogActionsStyles,
    dialogContentStyles,
    DialogSxProps,
    dialogTitleStyles,
} from './DialogComponent.style';

import DialogTypeEnumerator from '../../enumerators/DialogTypeEnumerator';

// ----------------------------------------------------------------Privates---
interface Props {
    dataToRemoved: string;
    dialogType: DialogTypeEnumerator;
    fields: JSX.Element;
    isOpened: boolean;
    handleClickClose: () => void;
    handleClickSave: () => void;
}

const DialogComponent = ({ dataToRemoved, dialogType, fields, isOpened, handleClickClose, handleClickSave }: Props): JSX.Element => {
    const theme: Theme = useTheme<Theme>();

    // --------------------------------------------------------------Return---
    return (
        <Dialog onClose={handleClickClose} open={isOpened} PaperProps={{ sx: DialogSxProps(theme).main }} >
            <DialogTitle style={dialogTitleStyles.main}>
                {dialogType.toString()}
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent style={dialogContentStyles.main}>
                {
                    ((dialogType === DialogTypeEnumerator.Remove) && (
                        <DialogContentText>
                            Biztos törlöd a megjelölt adatot:<br /><br />"{dataToRemoved}"?
                        </DialogContentText>
                    )) || (
                        <>
                            {fields}
                        </>
                    )
                }
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions style={dialogActionsStyles.main}>
                <Button onClick={handleClickClose} startIcon={<Close />} variant="outlined">
                    Mégsem
                </Button>
                <Button color={dialogType === DialogTypeEnumerator.Remove ? 'error' : 'success'} onClick={handleClickSave} startIcon={<Save />} variant="contained">
                    {dialogType.toString()}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default DialogComponent;
