// -----------------------------------------------------------------Imports---
import {
    createTheme,
    Theme,
} from '@mui/material';

import ThemeModeEnumerator from './enumerators/ThemeModeEnumerator';

import CSSPropertiesModel from './models/CSSPropertiesModel';
import SxPropsModel from './models/SxPropsModel';

// -----------------------------------------------------------------Exports---
export const globalTheme = (mode: ThemeModeEnumerator): Theme => {
    return createTheme({
        palette: {
            mode: mode,
        },
    });
}

export const boxStyles: CSSPropertiesModel = {
    page: {
        display: 'flex',
        flexDirection: 'column',
    },
}

export const BoxSxProps = (isDrawerOpened: boolean, drawerWidth: number, theme: Theme): SxPropsModel => {
    return {
        main: {
            flexGrow: 1,
            ...((isDrawerOpened) && {
                maxWidth: 'calc(100% - ' + drawerWidth + 'px)',
            }),
            ...((!isDrawerOpened) && {
                maxWidth: 'calc(100% - ' + theme.spacing(7) + ' - 1px)',
                [theme.breakpoints.up('sm')]: {
                    maxWidth: 'calc(100% - ' + theme.spacing(8) + ' - 1px)',
                },
            }),
            p: theme.spacing(4),
            [theme.breakpoints.down('lg')]: {
                p: theme.spacing(2),
            },
            [theme.breakpoints.down('md')]: {
                p: 0,
            },
        },
        subMain: {
            display: 'flex',
        },
    }
}
